import { VisitZyva } from "../models/visitZyva";

export class ZyvaHelper {
    static getMailTemplate(
        subject: string,
        orderZyva: VisitZyva,
        userName: string,
        userEmail: string,
        userId: string,
        ownerName: string,
        ownerAddress: string
    ) {
        const links = (orderZyva.uploadLinks?.length ? '<p>Liens des fichiers : </p>' + orderZyva.uploadLinks.join('<br/>') : '')

        return `
            <h1>${subject}</h1>
            <h3>Données de l'utilisateur qui a effectué la commande</h3>
            <p>Nom : ${userName}</p>
            <p>Email : ${userEmail}</p>
            <p>Identifiant de l'utilisateur : ${userId}</p>
            <hr /><br />
            <h3>Données concernant le bien et le propriétaire</h3>
            <p>Nom : ${ownerName}</p>
            <p>Lieu : ${ownerAddress}</p>
            <p>Clés disponibles en l'étude ? <span style="font-weight: 900;">${orderZyva.option.active ? 'OUI' : 'NON'}</span></p>
            <p>Instruction(s) particulière(s) : ${orderZyva.instructions ?? 'Aucune'}</p>
            ` + links;
    }
}
import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-inactifs-notaires',
  templateUrl: './inactifs-notaires.component.html',
  styleUrls: ['./inactifs-notaires.component.scss']
})
export class InactifsNotairesComponent implements OnInit {
  @Input() activeFilter;
  constructor() { }

  ngOnInit(): void {
  }

}

<div class="container success">
  <header id="dialog-header">
    <h5>
      {{ data.title }}
    </h5>
  </header>
  <div id="dialog-content" [innerHTML]="data.content"></div>

  <footer id="dialog-footer">
    <button (click)="cancel()" class="btn">{{ 'common.close' | translate }}</button>
  </footer>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-global-message',
  templateUrl: './global-message.component.html',
  styleUrls: ['./global-message.component.scss']
})
export class GlobalMessageComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { dialog: MatDialog }) {}

  ngOnInit() {}

  close() {
    this.data.dialog.closeAll();
  }
}

const prodAPI = 'https://certiservices.azurewebsites.net/';
const localAPI = 'http://localhost:52646/';
const apiRef = prodAPI;
export const certiCoreBaseUrl = 'https://certicore.azurewebsites.net';
export const certiSystemsBaseUrl = 'https://systems.certinergie.be';
export const certiServicesBaseUrl = 'https://certiservices.azurewebsites.net';

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAC3LGik6S0kYASfaAYMbcVUz5pxXXOj0s',
    authDomain: 'certimmo-930d3.firebaseapp.com',
    databaseURL: 'https://certimmo-930d3.firebaseio.com',
    projectId: 'certimmo-930d3',
    storageBucket: 'certimmo-930d3.appspot.com',
    messagingSenderId: '12993987359',
    appId: '1:12993987359:web:d3ce9ea6979c978c5e662a',
    measurementId: 'G-Z0C2QXWZH5'
  },
  release: '20241116.2',
  stripeCheckoutUrl: 'https://certinergie-stripe-prod.azurewebsites.net/api/checkout-session',
  stripeGetStatusUrl: 'https://certinergie-stripe-prod.azurewebsites.net/api/session-status',
  stripePublicKey: 'pk_live_RPnzJ2n7aIxtPjVP0zVoF9Z3',
  stripePublicKeyGreenfish: 'pk_live_k19XEZCDlk91w5cGmg2333HH00WzFL3USj',
  API: {
    SEND_BROCHURE: apiRef + 'api/notary/plaquette/sendmails'
  },
  CertiCore: {
    userOrdersUrl: certiCoreBaseUrl + '/api/v1/espaceclient/users/me/orders/',
    loginUrl: certiCoreBaseUrl + '/api/v1/espaceclient/auth/login/',
    refresToken: certiCoreBaseUrl + '/api/v1/espaceclient/auth/refreshtoken/',
    orderInfo: certiCoreBaseUrl + '/api/v1/espaceclient/orders/',
    publicOrderInfo: certiCoreBaseUrl + '/api/v1/espaceclient/orders/anonymous/',
    askActeToTrue: certiCoreBaseUrl + '/api/v1/espaceclient/orders/{orderId}/askacte',
    agreeToPay: certiCoreBaseUrl + '/api/v1/espaceclient/orders/{orderId}/agreetopay',
    notifications: certiCoreBaseUrl + '/api/v1/users/me/notifications',
    refreshUserInfo: certiCoreBaseUrl + '/api/v1/espaceclient/auth/me/',
    deleteNotifications: certiCoreBaseUrl + '/api/v1/users/me/notifications?orderId={orderId}',
    getElecAgents: certiCoreBaseUrl + '/api/v1/espaceclient/users/employees/elec',
    checkVat: certiCoreBaseUrl + '/api/v1/helpers/vat',
    agendaEmployees: certiCoreBaseUrl + '/api/v1/espaceclient/users/employees/elec/',
    sendDocReminder: certiCoreBaseUrl + '/api/v1/espaceclient/comments/rappel/',
    checkoutInfo: certiCoreBaseUrl + '/api/v1/espaceclient/orders/{orderId}/checkout',
    clientComment: certiCoreBaseUrl + '/api/v1/espaceclient/comments/public/',
    sms: certiCoreBaseUrl + '/api/v1/sms'
  },
  CertiSystems: {
    onlineAgendaUrl: certiSystemsBaseUrl + '/api/onlineagenda',
    loginWeb: certiSystemsBaseUrl + '/api/LoginWeb',
    mailSending: certiSystemsBaseUrl + '/api/mailsending',
    emailWithTemplate: certiSystemsBaseUrl + '/api/EmailWithTemplate',
    packPromo: certiSystemsBaseUrl + '/api/PackPromo',
    createNewUser: certiSystemsBaseUrl + '/api/CreateNewUser',
    orderInfo: certiSystemsBaseUrl + '/api/orderinfo'
  },
  CertiServices: {
    order: certiServicesBaseUrl + '/api/Order',
    primmo: certiServicesBaseUrl + '/api/primmo'
  },
  baseUrl: 'https://order.certinergie.be',
  certinergieBlobStorageBaseUrl: 'https://certinergie.blob.core.windows.net',
  certinergieNotaryAppBaseUrl: 'https://notary.certinergie.be',
  certiAdminBaseUrl: 'https://certiadmin.azurewebsites.net'
};

import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import * as orderSelector from '../../core/store/order/order.selectors';
import { AuthService } from 'src/app/core/services/auth.service';
import * as PRODUCTS from '../../core/data/products';
import { map } from 'rxjs/operators';
import { hasSplitInvoice } from 'src/app/core/data/theme-config';
import { SetSplitInvoice } from 'src/app/core/store/order';
import { Product } from 'src/app/core/models/product';

@Component({
  selector: 'app-split-invoice',
  templateUrl: './split-invoice.component.html',
  styleUrls: ['./split-invoice.component.scss']
})
export class SplitInvoiceComponent implements OnInit {
  $hasImmoServices: Observable<boolean>;
  $hasLegalServices: Observable<boolean>;
  $isAgency: Observable<boolean>;
  $isNotary: Observable<boolean>;
  $productsForAgency: Observable<Product[]>;

  @Input()
  showTitle = true;
  @Input()
  showDetails = true;

  hasSplitInvoice = hasSplitInvoice;
  splitInvoice = false;

  constructor(private store: Store<AppState>, private authService: AuthService) {}

  ngOnInit() {
    this.store.select(orderSelector.getSplitInvoice).subscribe(s => (this.splitInvoice = s));
    this.$productsForAgency = this.store.select(orderSelector.getProductsForAgency);
    this.$isAgency = this.authService.isAgency;
    this.$isNotary = this.authService.isNotary;
    this.$hasImmoServices = this.store
      .select(orderSelector.getProducts)
      .pipe(map(op => [...op].filter(pr => pr.id > 17 || pr.id === PRODUCTS.PLAN).length > 0));
    this.$hasLegalServices = this.store
      .select(orderSelector.getProducts)
      .pipe(map(op => [...op].filter(pr => pr.id === PRODUCTS.PEB || pr.id === PRODUCTS.ELEC).length > 0));
  }

  togglesplitInvoice() {
    this.store.dispatch(new SetSplitInvoice(this.splitInvoice));
  }
}

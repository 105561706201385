import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() message: string = '';
  @Input() variant: 'info' | 'success' | 'warning' | 'error' = 'info';
  @Input() icon: string = 'info';

  get variantClass() {
    return this.variant;
  }
}

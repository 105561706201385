import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../../../core/services/data.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile$: BehaviorSubject <any>;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getProfile();
    this.profile$=this.dataService.profile$
  }
}

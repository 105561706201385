import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators , FormControl } from '@angular/forms';
import { DataService } from '../../../core/services/data.service';
import {MatSnackBar , MatSnackBarConfig} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-profile-password',
  templateUrl: './profile-password.component.html',
  styleUrls: ['./profile-password.component.scss']
})
export class ProfilePasswordComponent implements OnInit {
  newPassword: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService
    ) {  }

  ngOnInit(): void {
    this.newPassword = this.fb.group({
      oldpassword: ['', [Validators.required]],
      newpassword: ['', [Validators.required]],
      confirmNewpassword: ['', [Validators.required]],
    });
  }

  confirmPass() {
    if (this.newPassword.value.newpassword !== this.newPassword.value.confirmNewpassword) {
      this.translate.get('profile.passwordsNotMatching').subscribe((res: string) => {
        const config = new MatSnackBarConfig();
        config.panelClass = ['redError'];
        config.duration=500000
        this.openSnackBar(res,'redError');
      });
    } else {
      const toSend = {
        OldPassword : this.newPassword.value.oldpassword,
        NewPassword: this.newPassword.value.newpassword
      };
      this.dataService.editPassword(toSend).pipe(catchError((err) => {
        return of({status: 400});
      })).subscribe((data: any) => {
        // this.error = data.status;
        if (data.status !== 204) {
          this.translate.get('profile.couldntEdit').subscribe((res: string) => {
            this.openSnackBar(res,'redError');
          });
        } else {
          this.translate.get('profile.success').subscribe((res: string) => {
            this.openSnackBar(res,'greenSuccess');
          });
        }
    });
    }
  }
  openSnackBar(message,cssClass) {
    this._snackBar.open(message, undefined ,{
    duration: 4000,
    panelClass: [cssClass]
});
  }

}

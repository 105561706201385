<ng-container *ngIf="!showAddParu">
  <header id="dialog-header">
    <h5>
      <img src="../../../assets/images/service-print-carte.svg" />
      {{
        (data.type === 'lavenir' ? 'print-request-dialog.carte.title' : 'print-request-dialog.carte.title-nvn')
          | translate
      }}
    </h5>
  </header>
  <div id="print-section">
    <p *ngIf="data.type === 'lavenir'" class="intro">{{ 'print-request-dialog.carte.intro-avenir' | translate }}</p>
    <p *ngIf="data.type !== 'lavenir'" class="intro">{{ 'print-request-dialog.carte.intro' | translate }}</p>
    <button *ngIf="paruList.length === 0" (click)="displayAddParu()" id="first-support" class="btn-accent">
      <fa-icon icon="plus"></fa-icon>
      {{ 'print-request-dialog.carte.add-print' | translate }}
    </button>
    <div id="print-list-container" *ngIf="paruList.length > 0">
      <ul id="print-list">
        <li *ngFor="let paru of paruList; let i = index">
          <div class="info">
            <span class="name ellipsis">{{ paru.name }}</span>
            <small>-</small>
            <span>{{ paru.date | date: 'dd/MM/yyyy' }}</span>
            <ng-container *ngIf="paru.needPhoto !== null">
              <small>-</small>
              <span>{{
                paru.needPhoto
                  ? ('print-request-dialog.carte.with-picture' | translate)
                  : ('print-request-dialog.carte.without-picture' | translate)
              }}</span>
            </ng-container>
            <ng-container *ngIf="currentParu.format !== ''">
              <small>-</small>
              <span>{{ paru.format }}</span>
            </ng-container>
            <small>-</small>
            <span>{{ 'section-money.htva' | translate }}: {{ paru.price | htva }}€</span>
          </div>
          <button
            (click)="editParu(i)"
            class="action action-edit"
            title="{{ 'print-request-dialog.carte.edit-title' | translate }}"
          >
            <fa-icon icon="pen"></fa-icon>
          </button>
          <button
            (click)="deleteParu(i)"
            class="action action-delete"
            title="{{ 'print-request-dialog.carte.delete-title' | translate }}"
          >
            <fa-icon icon="trash"></fa-icon>
          </button>
        </li>
      </ul>
      <button (click)="displayAddParu()" id="add-print">
        <fa-icon icon="plus"></fa-icon> {{ 'print-request-dialog.carte.add-print' | translate }}
      </button>
    </div>
  </div>
  <div id="comment-section">
    <p class="intro" *ngIf="projectID !== 'default'">
      {{ 'print-request-dialog.carte.pub-text' | translate }}
    </p>
    <form id="dialog-content" [formGroup]="infoFormGroup">
      <mat-form-field appearance="outline">
        <textarea
          matInput
          formControlName="instructions"
          cdkTextareaAutosize
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="5"
          placeholder="{{ 'print-request-dialog.carte.pub-text-input' | translate }}"
        ></textarea>
      </mat-form-field>
    </form>
  </div>
  <div id="files-section">
    <p class="intro">{{ 'print-request-dialog.carte.add-file-title' | translate }}</p>
    <div id="upload-files-trigger" *ngIf="uploadLinks.length === 0">
      <button class="btn-primary" (click)="fileInput.click()">
        <fa-icon icon="file-image"></fa-icon>
        {{ 'print-request-dialog.carte.choose-file' | translate }}
      </button>
      <span class="input-container">
        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
      </span>
    </div>
    <div id="upload-list-container" *ngIf="uploadLinks.length > 0">
      <ul id="upload-list">
        <li *ngFor="let file of uploadLinks; let i = index" class="upload-element">
          <div class="info">
            <span class="name ellipsis" matTooltip="{{ 'File ' + fileIndex(i) }}" matTooltipPosition="above"
              >{{ 'File ' + fileIndex(i) }}
            </span>
          </div>
          <a
            [href]="file | safeHtml"
            target="_blank"
            rel="noopener"
            class="action action-download"
            title="{{ 'print-request-dialog.carte.download-file-title' | translate }}"
          >
            <fa-icon icon="download"></fa-icon>
          </a>
          <button
            (click)="deletePhotoLink(i)"
            class="action action-delete"
            title="{{ 'print-request-dialog.carte.delete-file-title' | translate }}"
          >
            <fa-icon icon="trash"></fa-icon>
          </button>
        </li>
      </ul>
      <div id="upload-other-files-trigger">
        <button (click)="fileInput.click()">
          <fa-icon icon="file-image"></fa-icon>{{ 'print-request-dialog.carte.add-another-file' | translate }}
        </button>
        <span class="input-container">
          <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
        </span>
      </div>
    </div>
  </div>
  <footer id="dialog-footer">
    <button (click)="cancel()" class="btn">{{ 'common.cancel' | translate }}</button>
    <button (click)="saveParu()" class="btn-primary">{{ 'common.confirm' | translate }}</button>
  </footer>
</ng-container>
<ng-container *ngIf="showAddParu">
  <header id="dialog-header">
    <h5>
      <fa-icon icon="plus"></fa-icon>
      {{ 'print-request-dialog.add-parution.title' | translate }}
    </h5>
  </header>
  <div id="dialog-content">
    <form>
      <div class="form-element" id="support-choice">
        <label>{{ 'print-request-dialog.add-parution.name' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select name="selected-paru" [(ngModel)]="selectedChoice">
            <mat-option *ngFor="let paru of options; let i = index" (click)="setSupportType(i)" [value]="i">
              {{ paru.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="showPhotoSelect" class="form-element" id="add-photo">
        <label>{{ 'print-request-dialog.add-parution.picture' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select (valueChange)="onPhotoValueChanges()" name="need-photo" [(ngModel)]="currentParu.needPhoto">
            <mat-option [value]="true">
              {{ 'print-request-dialog.carte.with-picture' | translate }}
            </mat-option>
            <mat-option [value]="false">
              {{ 'print-request-dialog.carte.without-picture' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-element date-picker-container">
        <label>{{ 'print-request-dialog.add-parution.date' | translate }}</label>
        <mat-form-field appearance="outline" id="date-picker">
          <input
            [(ngModel)]="currentParu.date"
            name="date-parution"
            matInput
            [matDatepickerFilter]="filterOneDay.bind(this)"
            [matDatepicker]="picker"
            placeholder="DD/MM/YYYY"
            [min]="tomorrow"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <label>{{ 'print-request-dialog.add-parution.number' | translate }}</label>
        <mat-form-field class="repeat-number" appearance="outline">
          <input matInput [(ngModel)]="repeatNumber" [min]="1" [max]="52" type="number" placeholder="1" name="repeat" />
        </mat-form-field>
        <span> {{ 'print-request-dialog.add-parution.weeks' | translate }}</span>
      </div>
    </form>
    <ul id="info">
      <li *ngIf="currentParu.format !== ''">
        <span
          ><strong>{{ 'print-request-dialog.add-parution.size' | translate }}</strong></span
        >
        <span>{{ currentParu.format }}</span>
      </li>
      <li>
        <span
          ><strong>{{ 'print-request-dialog.add-parution.price' | translate }}</strong></span
        >
        <span>{{ 'section-money.htva' | translate }}: {{ finalPrice | htva }}€</span>
      </li>
    </ul>
    <div id="legal" class="caption">
      {{ 'print-request-dialog.add-parution.legal' | translate }}
    </div>
  </div>
  <footer id="dialog-footer">
    <button (click)="backToList()" class="btn">{{ 'common.cancel' | translate }}</button>
    <button *ngIf="!editing" (click)="addSupport()" class="btn-primary">{{ 'common.add' | translate }}</button>
    <button *ngIf="editing" (click)="saveEditSupport()" class="btn-primary">
      {{ 'common.edit' | translate }}
    </button>
  </footer>
</ng-container>

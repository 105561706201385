import { Component, OnInit , Input , OnChanges } from '@angular/core';

@Component({
  selector: 'app-comissions-unpaid',
  templateUrl: './comissions-unpaid.component.html',
  styleUrls: ['./comissions-unpaid.component.scss']
})
export class ComissionsUnpaidComponent implements OnInit {
  @Input() activeFilter;
  @Input() comissions;
  constructor() { }

  ngOnInit(): void {
    console.log(this.activeFilter)
  }
  ngOnChanges(){
    console.log(this.activeFilter)
  }

}

<div class="adress-container">
  <div class="adress-card" *ngIf="($isLoggedIn | async) && invoiceTo == 'toProprio' && !forceToPro">
    <header>
      <h6 class="subtitle">{{ 'step3.to-owner' | translate }}</h6>
    </header>
    <div>
      <p>
        <span>{{ 'step3.fullname' | translate }}:</span>&nbsp;
        <strong>{{ owner.companyname }} {{ owner.firstname }} {{ owner.lastname }}</strong>
      </p>
      <p>
        <span>{{ 'step3.address' | translate }}:</span>&nbsp;
        <strong
          >{{ address.street }} {{ address.number }} {{ address.boxNumber }} {{ address.apartmentNumber }},
          {{ address.zip }} {{ address.city }}</strong
        >
      </p>
    </div>
  </div>
  <div
    class="adress-card"
    *ngIf="($isLoggedIn | async) && invoiceTo == 'toProprioAndPro' && userType === UserTypeEnum.Notaire && !forceToPro"
  >
    <header>
      <h6 class="subtitle">{{ 'step3.to-owner-co-notary' | translate }}</h6>
    </header>
    <div>
      <p>
        <span>{{ 'step3.fullname' | translate }}:</span>&nbsp;
        <strong>{{ owner.firstname }} {{ owner.lastname }} C/o {{ user.commercialname }}</strong>
      </p>
      <p>
        <span>{{ 'step3.address' | translate }}:</span>&nbsp;
        <strong
          >{{ user.address.street }} {{ user.address.number }} {{ user.address.boxNumber }}
          {{ user.address.apartmentNumber }},
          {{ user.address.zip }}
          {{ user.address.city }}</strong
        >
      </p>
    </div>
  </div>
  <div
    class="adress-card"
    *ngIf="
      (($isLoggedIn | async) &&
        invoiceTo == 'toPro' &&
        (userType === UserTypeEnum.Notaire || userType === UserTypeEnum.Installateur)) ||
      forceToPro
    "
  >
    <header>
      <h6 class="subtitle">
        {{ (userType === UserTypeEnum.Installateur ? 'step3.to-insta' : 'step3.to-notary') | translate }}
      </h6>
    </header>
    <div>
      <p>
        <span>{{ 'step3.fullname' | translate }}:</span>&nbsp;<strong>{{ user.lastname }} {{ user.firstname }}</strong>
        {{ user.commercialname }}
      </p>
      <p>
        <span>{{ 'step3.address' | translate }}:</span>&nbsp;
        <strong
          >{{ user.address.street }} {{ user.address.number }} {{ user.address.boxNumber }}
          {{ user.address.apartmentNumber }},
          {{ user.address.zip }}
          {{ user.address.city }}</strong
        >
      </p>
    </div>
  </div>
  <div
    class="adress-card"
    *ngIf="($isLoggedIn | async) && invoiceTo == 'toProprioAndPro' && userType === UserTypeEnum.Agence"
  >
    <header>
      <h6 class="subtitle">{{ 'step3.to-owner-co-agency' | translate }}</h6>
    </header>
    <div>
      <p>
        <span>{{ 'step3.fullname' | translate }}:</span>&nbsp;
        <strong>{{ owner.firstname }} {{ owner.lastname }} C/o {{ user.commercialname }}</strong>
      </p>
      <p>
        <span>{{ 'step3.address' | translate }}:</span>&nbsp;
        <strong
          >{{ user.address.street }} {{ user.address.number }} {{ user.address.boxNumber }}
          {{ user.address.apartmentNumber }},
          {{ user.address.zip }}
          {{ user.address.city }}</strong
        >
      </p>
    </div>
  </div>
  <div class="adress-card" *ngIf="($isLoggedIn | async) && invoiceTo == 'toPro' && userType === UserTypeEnum.Agence">
    <header>
      <h6 class="subtitle">{{ 'step3.to-agency' | translate }}</h6>
    </header>
    <div>
      <p>
        <span>{{ 'step3.fullname' | translate }}:</span>&nbsp;<strong>{{ user.commercialname }}</strong>
      </p>
      <p>
        <span>{{ 'step3.address' | translate }}:</span>&nbsp;
        <strong
          >{{ user.address.street }} {{ user.address.number }} {{ user.address.boxNumber }}
          {{ user.address.apartmentNumber }},
          {{ user.address.zip }}
          {{ user.address.city }}</strong
        >
      </p>
    </div>
  </div>
  <div class="adress-card" *ngIf="!($isLoggedIn | async) && invoiceTo == 'toProprio' && !forceToPro">
    <header>
      <h6 class="subtitle">{{ 'step3.to-owner' | translate }}</h6>
    </header>
    <div>
      <p>
        <span>{{ 'step3.fullname' | translate }}:</span>&nbsp;
        <strong>{{ userForm.companyname }} {{ userForm.firstname }} {{ userForm.lastname }}</strong>
      </p>
      <p>
        <span>{{ 'step3.address' | translate }}:</span>&nbsp;
        <strong
          >{{ address.street }} {{ address.number }} {{ address.boxNumber }} {{ address.apartmentNumber }},
          {{ address.zip }} {{ address.city }}</strong
        >
      </p>
    </div>
  </div>
</div>

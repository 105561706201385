<section id="choice-immo" class="choice">
  <header>
    <img src="../../../assets/images/certinergie-notary-icon.svg" alt="" />
    <h4>{{ 'services-choice-dialog.choice-immo.title' | translate }}</h4>
  </header>
  <p>{{ 'services-choice-dialog.choice-immo.content' | translate }}</p>
  <p>{{ 'services-choice-dialog.choice-immo.exemple' | translate }}</p>
  <button (click)="close()" class="btn-primary">{{ 'services-choice-dialog.choice-immo.action' | translate }}</button>
</section>
<section id="choice-notaire" class="choice">
  <header>
    <img src="../../../assets/images/services-notaires-icon.svg" alt="" />
    <h4>{{ 'services-choice-dialog.choice-notaire.title' | translate }}</h4>
  </header>
  <p>{{ 'services-choice-dialog.choice-notaire.content' | translate }}</p>
  <p>{{ 'services-choice-dialog.choice-notaire.exemple' | translate }}</p>
  <button (click)="continue()" class="btn-accent">
    {{ 'services-choice-dialog.choice-notaire.action' | translate }}
  </button>
</section>

import { Component, ViewChild, ElementRef , OnInit } from '@angular/core';

@Component({
  selector: 'app-listing-notaire',
  templateUrl: './listing-notaire.component.html',
  styleUrls: ['./listing-notaire.component.scss']
})
export class ListingNotaireComponent implements OnInit {
  @ViewChild('all', { static: true }) all: ElementRef;
  @ViewChild('actifs', { static: true }) actifs: ElementRef;
  @ViewChild('inactifs', { static: true }) inactifs: ElementRef;
  @ViewChild('segmented', { static: true }) segmented: ElementRef;
  @ViewChild('table', { static: true }) table: ElementRef;
  activeTab='all';
  activeFilter='table';
  constructor() { }

  ngOnInit(): void {
  }
  setTab(arg){
    if(arg!==this.activeTab){
      if(arg=="inactifs" ){
        this[this.activeTab].nativeElement.classList.remove('tab-active')
        this[this.activeTab].nativeElement.classList.remove('green')
        this.activeTab= arg;
        this[arg].nativeElement.classList.add('tab-active');
      }else{
        this[this.activeTab].nativeElement.classList.remove('tab-active')
        this[this.activeTab].nativeElement.classList.remove('green')
        this.activeTab= arg;
        this[arg].nativeElement.classList.add('tab-active');
        this[arg].nativeElement.classList.add('green');
      }
    }
  }
  setFilter(arg){
    if(arg!==this.activeFilter){
      this[this.activeFilter].nativeElement.classList.remove('filter-active')
      this[this.activeFilter].nativeElement.classList.add('filter-not-active')
      this.activeFilter= arg;
      this[arg].nativeElement.classList.add('filter-active');
      this[arg].nativeElement.classList.remove('filter-not-active');
     }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { services, immoServices } from 'src/app/core/data';
import { OrderInfosDTO } from 'src/app/core/models/OrderDTO';
import { environment } from 'src/environments/environment';
import { CompanyEmployeeDTO } from 'src/app/core/models/companyEmployeeDTO';
import { share, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientOrdersService {
  private apiGetOrderDocs = `${environment.CertiServices.primmo}/clientdocuments/`;
  assetsFolder = 'assets/images/';

  constructor(private http: HttpClient) {}

  getOrderDocs(orderid: string): Observable<any> {
    if (!this.isGuid(orderid)) {
      return of(false);
    }
    return this.http.get<any>(this.apiGetOrderDocs + orderid);
  }

  askActeToTrue(orderid: string): Observable<any> {
    return this.http.post<any>(environment.CertiCore.askActeToTrue.replace('{orderId}', orderid), null);
  }

  agreeToPay(orderid: string): Observable<any> {
    return this.http.post<any>(environment.CertiCore.agreeToPay.replace('{orderId}', orderid), null);
  }

  public isGuid(stringToTest) {
    const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  }

  serviceImgUrl(id: number, selected = false) {
    let url = this.assetsFolder;

    url += this.getFileName(id);
    url += selected ? '-selected.svg' : '.svg';
    return url;
  }

  getOrderRef(order: OrderInfosDTO) {
    return this.getIdFromGuid(order.OrderId);
  }

  getFileName(id: number): string {
    id = id === 8 || id === 36 ? 4 : id === 16 ? 15 : id;
    let s: any = services.find(sv => sv.id === id);
    if (s === undefined) {
      s = immoServices.find(serv => serv.id === id);
    }
    return s === undefined ? 'service-descriptif' : s.fileName;
  }

  getIdFromGuid(guid: string, length = 8) {
    return guid.slice(guid.length - length);
  }

  isOrderFromNotaria(orderMeansType: number): boolean {
    return orderMeansType === 12 || orderMeansType === 13 || orderMeansType === 14 || orderMeansType === 17;
  }

  isClientAndThirdPartyOrder(orderMeansType: number, isThirdParyNotaria) {
    return this.isOrderFromNotaria(orderMeansType) && !isThirdParyNotaria;
  }

  isDateToday(dateString: string) {
    const today = new Date();
    const date = new Date(dateString);
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  addDayToDate(dateString: string, days: number) {
    const result = new Date(dateString);
    result.setDate(result.getDate() + days);
    return result;
  }

  markNotificationsToRead(orderId: string) {
    return this.http.delete<any>(environment.CertiCore.deleteNotifications.replace('{orderId}', orderId));
  }

  getElecAgents(): Observable<CompanyEmployeeDTO[]> {
    return this.http.get<any>(environment.CertiCore.getElecAgents).pipe(shareReplay(1));
  }
}

import { Component, OnInit , Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from '../../../core/services/data.service';
@Component({
  selector: 'app-delete-contact',
  templateUrl: './delete-contact.component.html',
  styleUrls: ['./delete-contact.component.scss']
})
export class DeleteContactComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dataService: DataService) { }

  ngOnInit(): void {
  }
  deleteContact(id){
    this.dataService.deleteContact(id).subscribe(data =>
      this.dataService.getProfile())
    this.data.dialog.closeAll()

  }
  annuler(){
    this.data.dialog.closeAll()
  }
}

import { Component, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FormBuilder } from '@angular/forms';
import * as orderSelector from '../../core/store/order/order.selectors';
import { forceInvoiceToPro } from '../../core/data/theme-config';

import { Owner, User, UserForm, UserType } from 'src/app/core/models/user';
import { Address } from 'src/app/core/models/address';
import { AppState } from 'src/app/interfaces';
import { Document } from 'src/app/core/models/document';

@Component({
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.scss']
})
export class BillingInfo {
  invoiceTo: string;
  $invoiceTo: Observable<string>;
  $isLoggedIn: Observable<boolean>;

  UserTypeEnum = UserType;

  user: User;
  userForm: UserForm;
  address: Address;
  owner: Owner;
  userType: number;
  document: Document;
  forceToPro = forceInvoiceToPro;

  constructor(private store: Store<AppState>, private _formBuilder: FormBuilder, private cdRef: ChangeDetectorRef) {
    this.store.select(orderSelector.getDocument).subscribe(d => (this.document = d));
    this.$isLoggedIn = this.store.select(orderSelector.getIsLoggedIn);
  }

  ngOnInit() {
    this.$invoiceTo = this.store.select(orderSelector.getInvoiceTo);
    this.$invoiceTo.subscribe(i => (this.invoiceTo = i));

    this.store.select(orderSelector.getUserInfo).subscribe(u => (this.user = u));
    this.store.select(orderSelector.getUserForm).subscribe(u => (this.userForm = u));
    this.store.select(orderSelector.getOwner).subscribe(o => (this.owner = o));
    this.store.select(orderSelector.getAddress).subscribe(a => (this.address = a));
    this.store.select(orderSelector.getUserType).subscribe(t => (this.userType = t));
  }
}

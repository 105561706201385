export const packsTheme = [
  {
    description: '51',
    estate: 3,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 4,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 11,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 4,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 1,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 4,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 12,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 14,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 13,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 24,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 4,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 4,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 7,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 4,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 8,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 34,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 9,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 34,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 10,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 34,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: 5,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 24,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  },
  {
    description: '51',
    estate: null,
    id: null,
    name: 'Combi PEB + ELEC ',
    reduc: 0,
    region: null,
    services: [4, 1],
    show: true,
    userRoles: [],
    userTypes: [1688, 1689, 8122, 1691],
    servicesPrice: 0,
    servicesRed: 0,
    totalWithReduc: 0
  }
];


export const TitleType = [
   { text : "Monsieur", value : "Monsieur"},
   { text : "Madame", value : "Madame" },
   { text : "Mademoiselle", value : "Mademoiselle" },
   { text : "Madame et Monsieur", value : "Madame et Monsieur" },
   { text : "Messieurs", value : "Messieurs" },
   { text : "Mesdames", value : "Mesdames" },
   { text : "Mesdemoiselles", value : "Mesdemoiselles" },
   { text : "Professeur", value : "Professeur" },
   { text : "Docteur", value : "Docteur" },
   { text : "Maître", value : "Maître" },
   { text : "Administration provisoire", value : "Administration provisoire"},
   { text : "Indivision", value : "Indivision" },
   { text : "Succession", value : "Succession" },
   { text : "Veuve", value : "Veuve" },
   { text : "Veuf", value : "Veuf" }
]

import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileEditComponent } from '../profile-edit/profile-edit.component';
@Component({
  selector: 'app-profile-pro-main',
  templateUrl: './profile-pro-main.component.html',
  styleUrls: ['./profile-pro-main.component.scss']
})
export class ProfileProMainComponent implements OnInit {
  @Input() profile;
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}
  editInfo() {
    this.dialog.open(ProfileEditComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog, profile: this.profile }
    });
  }
}

<div id="dialog-container" *ngIf="!this.sent">
  <header id="dialog-header">
    <h5>
      <fa-icon icon="file-invoice"></fa-icon>
      {{ 'specific-print-request-dialog.title' | translate }}
    </h5>
  </header>
  <div id="dialog-intro">
    <p>
      {{ 'specific-print-request-dialog.content' | translate }}
    </p>
  </div>
  <form id="dialog-content" (ngSubmit)="onSubmit()" [formGroup]="devisFormGroup">
    <mat-form-field appearance="outline">
      <textarea
        matInput
        formControlName="instructions"
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
        placeholder="{{ 'specific-print-request-dialog.publication-instructions' | translate }}"
      ></textarea>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <textarea
        matInput
        formControlName="description"
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
        placeholder="{{ 'specific-print-request-dialog.publication-description' | translate }}"
      ></textarea>
    </mat-form-field>
  </form>
  <footer id="dialog-footer">
    <button (click)="close()" class="btn">{{ 'common.cancel' | translate }}</button>
    <button type="submit" class="btn-primary">
      {{ 'specific-request-dialog.send-request-btn' | translate }}
    </button>
  </footer>
</div>
<div id="dialog-confirm" *ngIf="this.sent">
  <picture>
    <fa-icon icon="check"></fa-icon>
  </picture>
  <h6>{{ 'specific-request-dialog.success-title' | translate }}</h6>
  <p>
    {{ 'specific-request-dialog.success-content' | translate }}
  </p>
  <button (click)="close()" class="btn">{{ 'specific-request-dialog.success-action' | translate }}</button>
</div>

export const FunctionType = [
    {
      name : "Manager",
      value : 0
    },
    {
      name : "Member",
      value : 2
    },
    {
      name : "Planning",
      value : 3
    },
    {
      name : "Accounting",
      value : 4
    },
    {
      name : "MainContact",
      value : 5
    }
  ]

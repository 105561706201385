<div class="wrapper" [class.hidden]="!show">
  <div *ngIf="show" class="loader-container">
    <div class="loader">
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__bar"></div>
      <div class="loader__ball"></div>
    </div>
    <p>Loading…</p>
  </div>
</div>

<div class="stepper-main-container">
  <mat-horizontal-stepper class="certiorder" [linear]="true" #stepper>
    <mat-step [editable]="true" [stepControl]="frmStep1">
      <ng-template matStepLabel>
        <span class="icon"><fa-icon icon="calculator"></fa-icon></span>
        <span class="label">{{ 'steps.title-1' | translate }}</span>
      </ng-template>
      <h5 class="step-title">{{ 'steps.title-1' | translate }}</h5>
      <!-- <h5 class="step-introduction">
        {{ 'step1.header-calculate-here' | translate }}
        <small>
          {{ ($hasImmoAccess | async) ? ('step1.header-immo' | translate) : ('step1.header-no-immo' | translate) }}
        </small>
      </h5> -->
      <app-step1 [packs]="packs" [stepper]="stepper"></app-step1>
      <!-- <div class="d-flex justify-content-end">
        <button mat-raised-button [color]="'primary'" (click)="complete()">
          Continuer1
        </button>
      </div> -->
    </mat-step>
    <mat-step [editable]="true" [completed]="isStep2Valid">
      <ng-template matStepLabel>
        <span class="icon"><fa-icon icon="home"></fa-icon></span>
        <span class="label">{{ 'section-user.disco.your-info' | translate }}</span>
      </ng-template>
      <h5 class="step-title">{{ 'section-user.disco.your-info' | translate }}</h5>
      <app-step2 [stepper]="stepper"></app-step2>
    </mat-step>
    <mat-step [editable]="true" [completed]="isStep3Valid">
      <ng-template matStepLabel>
        <span class="icon"><fa-icon icon="shipping-fast"></fa-icon></span>
        <span class="label">{{ 'steps.title-3' | translate }}</span>
      </ng-template>
      <h5 class="step-title">{{ 'steps.title-3' | translate }}</h5>
      <app-step3></app-step3>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        <span class="icon"><fa-icon icon="clipboard-check"></fa-icon></span>
        <span class="label">{{ 'steps.title-4' | translate }}</span>
      </ng-template>
      <h5 class="step-title">{{ 'steps.title-4' | translate }}</h5>
      <app-step4></app-step4>
    </mat-step>
  </mat-horizontal-stepper>
</div>

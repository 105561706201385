import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './interfaces';
import { combineLatest } from 'rxjs';

import * as orderSelector from './core/store/order/order.selectors';
import * as uiSelector from './core/store/ui/ui.selectors';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { StartOnlineOfflineCheck, SetLanguage, SetIsMobile, SetIsTablette } from './core/store/ui';
import { OrderSteppersComponent } from './order-steppers/order-steppers.component';

import { MatIconRegistry } from '@angular/material/icon';

import { UpdateSwService } from './core/services/update-sw.service';
import { AuthService } from './core/services/auth.service';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { projectID } from './core/data/theme-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(OrderSteppersComponent, { static: false }) OrderSteppersComponent: OrderSteppersComponent;

  isEmbedded: boolean = false;
  currentUserId: string | undefined = undefined;

  constructor(
    private store: Store<AppState>,
    public breakpointObserver: BreakpointObserver,
    translate: TranslateService,
    private sw: UpdateSwService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    translate.setDefaultLang('fr');
    this.store.select(uiSelector.getLanguage).subscribe(ln => translate.use(ln));
    this.iconRegistry.addSvgIcon(
      'notifications',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/notifications.svg')
    );
  }

  ngOnInit() {
    this.store.dispatch(new StartOnlineOfflineCheck());
    // check the service worker for updates
    this.sw.checkForUpdates();

    // set the title of the page
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe(() => {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' | ');
        this.titleService.setTitle(this.getWebsiteTitle() + (title === '' ? '' : ' | ' + title));
      });

    // check if the user is on a mobile device
    this.breakpointObserver.observe(['(min-width: 576px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.store.dispatch(new SetIsMobile(false));
      } else {
        this.store.dispatch(new SetIsMobile(true));
      }
    });

    // check if the user is on a tablet device
    this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.store.dispatch(new SetIsTablette(false));
      } else {
        this.store.dispatch(new SetIsTablette(true));
      }
    });

    // NEEDS REFACTORING

    // check if the user is logged in
    this.route.queryParamMap.subscribe(params => {
      const userid = params.get('u') ?? null;
      const redirectUrl = params.get('redirect') ?? null;
      const lan = params.get('ln') ?? null;
      this.isEmbedded = params.get('embedded') === 'true';

      if (['fr', 'nl', 'de', 'en'].includes(lan)) {
        this.store.dispatch(new SetLanguage(lan as 'fr' | 'nl' | 'de' | 'en'));
      }

      // if the user is automatically logged in
      if (userid) {
        this.authService.loginWithUserIDAsync(userid, true, 0)
          .then(() => { 
            this.currentUserId = userid;
            this.router.navigate([redirectUrl ?? this.router.url.split('?')[0]], {
              queryParams: {
                u: null,
                redirect: null
              },
              queryParamsHandling: 'merge'
            });
          })
          .catch(() => {
            this.router.navigate(['/']);
          });
      }
      else 
      {
        //check if the user is logged in before with the user id or email
        combineLatest(
          this.store.select(orderSelector.getUserID),
          this.store.select(orderSelector.getUserEmail),
          (uid, email) => ({
            uid,
            email
          })
        ).subscribe(({ uid, email }) => {
          if (uid && uid !== '' && this.currentUserId !== uid  && email === '') {
            this.currentUserId = uid;
            this.authService.loginWithUserIDAsync(uid)
          }
        });
      }

      // if the user is not logged in and there is a redirect url
      if (!userid && redirectUrl) {
        this.authService.navigateToSubUrl(redirectUrl);
      }

      // Refresh token on app init
      if (this.authService.userId) this.authService.refreshToken(this.authService.userId);
    });

    // check if the user is logged in then refresh the user info
    this.store.select(orderSelector.getIsLoggedIn).subscribe(logged => {
      if (logged) {
        this.authService.refreshUserInfo();
      }
    });
  }

  ngAfterViewInit(): void {
    const ieIE = this.navigatorIsIE();

    if (ieIE) {
      alert(
        'Our website is not compatible with Internet Explorer, please use any other modern browser (Chrome, Firefox, EDGE, Safari,...)'
      );
    }
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  getWebsiteTitle(): string {
    return projectID === 'greenfish'
      ? 'Greenfish'
      : projectID === 'hainaut'
      ? 'MDNH'
      : projectID === 'namur'
      ? 'MDNN'
      : projectID === 'liege'
      ? 'MDNL'
      : projectID === 'nvn'
      ? 'NVN'
      : 'Certinergie';
  }

  navigatorIsIE(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdressType } from '../../../core/enums/adress-type.enum';
import { DataService } from '../../../core/services/data.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
@Component({
  selector: 'app-add-adress-dialog',
  templateUrl: './add-adress-dialog.component.html',
  styleUrls: ['./add-adress-dialog.component.scss']
})
export class AddAdressDialogComponent implements OnInit {
  newAdress: FormGroup;
  displayError = false;
  errorContent = 'Veuillez bien remplir le formulaire';
  errorCreation = 'Un problème est survenu , réessayer';
  adressType = AdressType;
  myModel = true;
  myModel2 = true;
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.newAdress = this.fb.group({
      Country: ['Belgium'],
      City: ['', [Validators.required]],
      PostalCode: ['', [Validators.required]],
      Street: ['', [Validators.required]],
      Num: ['', [Validators.required]],
      Name: ['', [Validators.required]],
      NotReceiveInvoice: [false, [Validators.required]],
      NotReceiveReport: [false, [Validators.required]],
      Type: [0, [Validators.required]],
      Latitude: ['', [Validators.required]],
      Longitude: ['', [Validators.required]],
      AppartementNumber: [''],
      BoxNumber: ['']
    });
  }
  autoComplete(adress) {
    adress.address_components.forEach(element => {
      element.types.forEach(y => {
        if (y.includes('street_number')) {
          this.newAdress.controls['Num'].setValue(element.long_name);
        } else if (y.includes('route')) {
          this.newAdress.controls['Street'].setValue(element.long_name);
        } else if (y.includes('administrative_area_level_1')) {
          this.newAdress.controls['City'].setValue(element.long_name);
        } else if (y.includes('country')) {
          this.newAdress.controls['Country'].setValue(element.long_name);
        } else if (y.includes('postal_code')) {
          this.newAdress.controls['PostalCode'].setValue(element.long_name);
        }
      });
    });
    this.newAdress.controls['Latitude'].setValue(adress.geometry.location.lat());
    this.newAdress.controls['Longitude'].setValue(adress.geometry.location.lng());
  }
  sendNewAdress() {
    if (this.newAdress.controls['NotReceiveInvoice'].value == '') {
      this.newAdress.controls['NotReceiveInvoice'].setValue(false);
    } else {
      this.newAdress.controls['NotReceiveInvoice'].setValue(true);
    }
    if (this.newAdress.controls['NotReceiveReport'].value == '') {
      this.newAdress.controls['NotReceiveReport'].setValue(false);
    } else {
      this.newAdress.controls['NotReceiveReport'].setValue(true);
    }
    if (this.newAdress.status == 'VALID') {
      this.displayError = false;
      this.myModel = !this.newAdress.get('NotReceiveInvoice').value;
      this.myModel2 = !this.newAdress.get('NotReceiveReport').value;
      this.newAdress.get('NotReceiveInvoice').setValue(!this.newAdress.get('NotReceiveInvoice').value);
      this.newAdress.get('NotReceiveReport').setValue(!this.newAdress.get('NotReceiveReport').value);
      this.dataService.createAdress(this.newAdress.value).subscribe(data => {
        if ((data.status = 204)) {
          this.data.dialog.closeAll();
          this.dataService.getProfile();
        }
      });
    } else {
      this.displayError = true;
    }
  }
}

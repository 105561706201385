<header id="dialog-header">
  <h5>
    <img src="../../../assets/images/dialog-warning-icon.svg" alt="" />{{ 'summary-confirm-dialog.title' | translate }}
  </h5>
</header>
<div id="dialog-content">
  <section class="step1-summary">
    <div class="item item-small">
      <h6>{{ 'section-situation.title' | translate }}</h6>
      <div class="content">
        <picture>
          <img [src]="getRegionImg(data.regionId)" alt="" />
        </picture>
        <span>{{ 'section-situation.region.' + data.regionId | translate }}</span>
      </div>
    </div>
    <div class="item item-small">
      <h6>{{ 'section-type.title' | translate }}</h6>
      <div class="content">
        <picture>
          <img [src]="getEstateImg(data.estateTypeId)" alt="" />
        </picture>
        <span>{{ 'estateTpes.' + data.estateTypeId | translate }}</span>
      </div>
    </div>
    <div class="item item-big">
      <h6>{{ 'summary-confirm-dialog.services' | translate }}</h6>
      <div class="content-container">
        <div *ngFor="let productId of data.products" class="content">
          <picture>
            <img [src]="serviceImgUrl(productId)" alt="" />
          </picture>
          <span>{{ 'section-money.reductions.' + productId | translate }}</span>
        </div>
      </div>
    </div>
  </section>
  <section class="price">
    <div class="title">{{ 'section-money.total' | translate }}</div>
    <div class="result">
      <span class="htva">
        <small>{{ 'section-money.htva' | translate }}</small>
        {{ data.price - data.reduction | htva }} €
      </span>
      <span>
        <small>{{ 'section-money.tvac' | translate }}</small>
        {{ data.price - data.reduction }} €
      </span>
    </div>
  </section>
</div>
<footer id="dialog-footer">
  <button [mat-dialog-close]="editOrder" (click)="backToEditOrder()" class="btn-warning">
    {{ 'navigation.show-options' | translate }}
  </button>
  <button (click)="next()" cdkFocusInitial class="btn-primary">{{ 'navigation.next' | translate }}</button>
</footer>

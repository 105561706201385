import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-stats-graph',
  templateUrl: './stats-graph.component.html',
  styleUrls: ['./stats-graph.component.scss']
})
export class StatsGraphComponent implements OnInit {
  @Input() stats;
  @Input() language;
  @ViewChild('sixMonth', { static: true }) sixMonth: ElementRef;
  @ViewChild('year', { static: true }) year: ElementRef;
  @ViewChild('all', { static: true }) all: ElementRef;
  @ViewChild('monthType', { static: false }) monthType: ElementRef;
  @ViewChild('sixMonthType', { static: false }) sixMonthType: ElementRef;
  @ViewChild('yearType', { static: true }) yearType: ElementRef;
  @ViewChild('allType', { static: true }) allType: ElementRef;
  @ViewChild('containerRef', { static: true }) containerRef: ElementRef;
  view: any[] = [1100, 300];
  view2: any[] = [400, 300];
  activeTab = 'sixMonth';
  activeTabType = 'monthType';
  xAxis: boolean = true;
  colorScheme = {
    domain: ['#5AA454']
  };
  typeOrdersData = [];
  typeOrderColor = {
    domain: [
      '#9AC356',
      '#E96136',
      '#FFB946',
      '#AAAAAA',
      '#537D8D',
      '#372248',
      '#30BCED',
      '#C2E812',
      '#01172F',
      '#4C212A'
    ]
  };
  monthTypeData = [];
  sixMonthTypeData = [];
  yearTypeData = [];
  allTypeData = [];
  totalMonth = 0;
  totalSixMonth = 0;
  totalYear = 0;
  totalAll = 0;
  sixMonthData = [
    {
      name: 'commandes éffectuées',
      series: []
    }
  ];
  yearData = [
    {
      name: 'Commandes éffectuées',
      series: []
    }
  ];
  allData = [
    {
      name: 'Commandes éffectuées',
      series: []
    }
  ];

  data;

  ngOnInit(): void {
    this.getStats();
  }
  getStats() {
    const date = new Date();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    let array = [];
    this.stats.MonthlyStatistics.forEach(element => {
      if (month < 6) {
        if (element.Year === year && element.Month <= month) {
          array.push(element);
        }
        if (element.Year === year - 1 && element.Month > 6 + month) {
          array.push(element);
        }
      } else if (element.Year === year && element.Month > month - 6 && element.Month <= month) {
        array.push(element);
      }
    });
    if (month < 6) {
      for (let index = 6 + month + 1; index <= 12; index++) {
        const found = array.find(element => element.Month === index);
        if (found) {
          this.sixMonthData[0].series.push({
            name: this.getMonthName(found.Month),
            value: found.OrderAmount,
            products: found.Products
          });
        } else {
          this.sixMonthData[0].series.push({
            name: this.getMonthName(index),
            value: 0,
            products: []
          });
        }
      }
      for (let index = 1; index <= month; index++) {
        const found = array.find(element => element.Month == index);
        if (found) {
          this.sixMonthData[0].series.push({
            name: this.getMonthName(found.Month),
            value: found.OrderAmount,
            products: found.Products
          });
        } else {
          this.sixMonthData[0].series.push({
            name: this.getMonthName(index),
            value: 0,
            products: []
          });
        }
      }
    } else {
      for (let index = month - 6 + 1; index <= month; index++) {
        const found = array.find(element => element.Month == index);
        if (found) {
          this.sixMonthData[0].series.push({
            name: this.getMonthName(found.Month),
            value: found.OrderAmount,
            products: found.Products
          });
        } else {
          this.sixMonthData[0].series.push({
            name: this.getMonthName(index),
            value: 0,
            products: []
          });
        }
      }
    }

    this.data = this.sixMonthData;
    //this year
    array = [];
    this.stats.MonthlyStatistics.forEach(element => {
      if (element.Year === year && element.Month <= month) {
        array.push(element);
      } else if (element.Year === year - 1 && element.Month > month) {
        array.push(element);
      }
    });
    for (let index = month + 1; index <= 12; index++) {
      const found = array.find(element => element.Month == index && element.Year === year - 1);
      if (found) {
        this.yearData[0].series.push({
          name: this.getMonthName(found.Month),
          value: found.OrderAmount,
          products: found.Products
        });
      } else {
        this.yearData[0].series.push({
          name: this.getMonthName(index),
          value: 0,
          products: []
        });
      }
    }
    for (let index = 1; index <= month; index++) {
      const found = array.find(element => element.Month == index && element.Year == year);
      if (found) {
        this.yearData[0].series.push({
          name: this.getMonthName(found.Month),
          value: found.OrderAmount,
          products: found.Products
        });
      } else {
        this.yearData[0].series.push({
          name: this.getMonthName(index),
          value: 0,
          products: []
        });
      }
    }
    // ALL data
    const first = this.stats.MonthlyStatistics[0];
    let loop = first.Month;
    for (let index = first.Year; index <= year; index++) {
      for (let j = loop; j <= 12; j++) {
        const found = this.stats.MonthlyStatistics.find(element => element.Month == j && element.Year == index);
        if (found) {
          this.allData[0].series.push({
            name: this.getMonthName(found.Month) + ' ' + index,
            value: found.OrderAmount
          });
        } else {
          this.allData[0].series.push({
            name: this.getMonthName(j) + ' ' + index,
            value: 0
          });
        }
      }
      loop = 1;
    }
    //this.allData[0].series = this.allData[0].series.slice(0, month + -12);

    //typeOrders
    //thisMonth
    let found = this.stats.MonthlyStatistics.find(element => element.Year == year && element.Month == month);
    if (found) {
      found.Products.forEach(element => {
        this.monthTypeData.push({
          name: element.Label,
          value: element.Amount
        });
        this.totalMonth += element.Amount;
      });
    }
    this.monthTypeData.sort((a, b) => (a.value < b.value ? 1 : -1));
    this.typeOrdersData = this.monthTypeData;
    //6months
    this.sixMonthData[0].series.forEach(element => {
      if (element.products) {
        element.products.forEach(element => {
          if (!this.sixMonthTypeData.some(el => el.name === element.Label)) {
            this.sixMonthTypeData.push({
              name: element.Label,
              value: element.Amount
            });
            this.totalSixMonth += element.Amount;
          } else {
            let index = this.sixMonthTypeData.findIndex(x => x.name === element.Label);
            this.sixMonthTypeData[index].value += element.Amount;
            this.totalSixMonth += element.Amount;
          }
        });
      }
    });
    this.sixMonthTypeData.sort((a, b) => (a.value < b.value ? 1 : -1));
    //if thismonthTypeData empty set 6monthTypeData
    if (this.monthTypeData.length == 0) {
      this.typeOrdersData = this.sixMonthTypeData;
      this.activeTabType = 'sixMonthType';
    }

    //last year
    this.yearData[0].series.forEach(element => {
      if (element.products) {
        element.products.forEach(element => {
          if (!this.yearTypeData.some(el => el.name === element.Label)) {
            this.yearTypeData.push({
              name: element.Label,
              value: element.Amount
            });
            this.totalYear += element.Amount;
          } else {
            let index = this.yearTypeData.findIndex(x => x.name === element.Label);
            this.yearTypeData[index].value += element.Amount;
            this.totalYear += element.Amount;
          }
        });
      }
    });
    this.yearTypeData.sort((a, b) => (a.value < b.value ? 1 : -1));
    //entire period
    this.stats.MonthlyStatistics.forEach(element => {
      element.Products.forEach(element => {
        if (!this.allTypeData.some(el => el.name === element.Label)) {
          this.allTypeData.push({
            name: element.Label,
            value: element.Amount
          });
          this.totalAll += element.Amount;
        } else {
          let index = this.allTypeData.findIndex(x => x.name === element.Label);
          this.allTypeData[index].value += element.Amount;
          this.totalAll += element.Amount;
        }
      });
    });
    this.allTypeData.sort((a, b) => (a.value < b.value ? 1 : -1));
  }
  //toRefactor
  getPourcentage(product) {
    if (this.typeOrdersData == this.monthTypeData) {
      return (product / this.totalMonth) * 100;
    }
    if (this.typeOrdersData == this.sixMonthTypeData) {
      return (product / this.totalSixMonth) * 100;
    }
    if (this.typeOrdersData == this.yearTypeData) {
      return (product / this.totalYear) * 100;
    }
    if (this.typeOrdersData == this.allTypeData) {
      return (product / this.totalAll) * 100;
    }
  }

  setTab(param, data) {
    if (param !== this.activeTab) {
      if (param == 'all' && data[0].series.length > 20) {
        this.xAxis = true;
      } else {
        this.xAxis = true;
      }
      this[this.activeTab].nativeElement.classList.remove('active');
      this.activeTab = param;
      this[param].nativeElement.classList.add('active');
      this.data = data;
    }
  }
  setTabType(param, data) {
    if (param !== this.activeTabType) {
      this[this.activeTabType].nativeElement.classList.remove('active');
      this.activeTabType = param;
      this[param].nativeElement.classList.add('active');
      this.typeOrdersData = data;
    }
  }

  getMonthName(monthId) {
    if (this.language == 'fr') {
      switch (monthId) {
        case 1:
          return 'Janvier';
          break;
        case 2:
          return 'Fevrier';
        case 3:
          return 'Mars';
        case 4:
          return 'Avril';
        case 5:
          return 'Mai';
        case 6:
          return 'Juin';
        case 7:
          return 'Juillet';
        case 8:
          return 'Aout';
        case 9:
          return 'Septembre';
        case 10:
          return 'Octobre';
        case 11:
          return 'Novembre';
        case 12:
          return 'Decembre';
      }
    } else {
      switch (monthId) {
        case 1:
          return 'Januari';
        case 2:
          return 'Februari';
        case 3:
          return 'Maart';
        case 4:
          return 'April';
        case 5:
          return 'Mei';
        case 6:
          return 'Juni';
        case 7:
          return 'Juli';
        case 8:
          return 'Augustus';
        case 9:
          return 'September';
        case 10:
          return 'Oktober';
        case 11:
          return 'November';
        case 12:
          return 'December';
      }
    }
  }
}

export class ProductDB {
  OrderPriceID: number;
  Region?: number;
  EstateType?: number;
  ProductType: number;
  Price: number;
  Reduction: number;
  ReductionPro: number;
  ReductionBronze: number;
  ReductionSilver: number;
  ReductionGold: number;
  ReductionPlatinum: number;
  OrderType: number;
  Commission?: number;
  SaleType?: number;
  WithPhoto?: boolean;
  StartPostalCode?: number;
  EndPostalCode?: number;
}

<header id="dialog-header">
  <h5>
    <img src="../../../assets/images/service-print-forfait.svg" />
    {{ 'print-request-dialog.forfait.title' | translate }} - {{ 'section-money.htva' | translate }}:
    {{ fullPressPrint.price | htva }}€
  </h5>
</header>
<div id="dialog-intro">
  <p>{{ 'print-request-dialog.forfait.intro' | translate }}</p>
</div>
<div id="dialog-content">
  <ul id="pack-list">
    <li>
      <img src="../../../assets/images/service-print-carte.svg" />
      Le soir
    </li>
    <li>
      <img src="../../../assets/images/service-print-carte.svg" />
      La libre / La DH
    </li>
    <li>
      <img src="../../../assets/images/service-print-carte.svg" />
      De standaard / Het Niewsblad
    </li>
    <li>
      <img src="../../../assets/images/service-print-carte.svg" />
      Le Vlan
    </li>
  </ul>
  <div id="date-picker-container">
    <label>{{ 'print-request-dialog.forfait.date-label' | translate }}</label>
    <mat-form-field appearance="outline" id="date-picker">
      <input
        [(ngModel)]="fullPressPrint.date"
        name="date-parution"
        matInput
        [matDatepickerFilter]="filterOneDay.bind(this)"
        [matDatepicker]="picker"
        placeholder="DD/MM/YYYY"
        [min]="tomorrow"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div id="date-picker-container">
    <label>{{ 'print-request-dialog.forfait.nb-of-weeks' | translate }}</label>
    <mat-form-field appearance="outline">
      <mat-select [(value)]="fullPressPrint.nbOfWeeks">
        <mat-option *ngFor="let nb of nbOptions" [value]="nb">
          {{ nb }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="price-weeks" *ngIf="fullPressPrint.nbOfWeeks > 1">
    <h5>
      {{ 'section-money.total' | translate }} {{ 'section-money.htva' | translate }}:
      {{ fullPressPrint.nbOfWeeks * fullPressPrint.price | htva }} €
    </h5>
  </div>
</div>
<footer id="dialog-footer">
  <button (click)="cancel()" class="btn">{{ 'common.cancel' | translate }}</button>
  <button (click)="saveFullPress()" class="btn btn-primary">{{ 'common.confirm' | translate }}</button>
</footer>

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import * as PRODUCTS from '../../core/data/products';

@Component({
  selector: 'app-services-details-dialog',
  templateUrl: './services-details-dialog.component.html',
  styleUrls: ['./services-details-dialog.component.scss']
})
export class ServicesDetailsDialogComponent implements OnInit {
  onAddService = new BehaviorSubject(0);

  PRODUCTS = PRODUCTS;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog; serviceId: number; venteType: number }
  ) {}

  closeDialog() {
    this.data.dialog.closeAll();
  }

  ngOnInit() {}

  addServiceNoClose(id: number) {
    this.onAddService.next(id);
  }

  addService(id: number) {
    this.onAddService.next(id);
    this.closeDialog();
  }

  get displayAddService() {
    return !(
      this.data.serviceId === PRODUCTS.LIVRET ||
      this.data.serviceId === PRODUCTS.AFFICHETTE ||
      this.data.serviceId === PRODUCTS.PLAQUETTE
    );
  }
}

<div class="container">
  <h5>
    {{ 'global-message.title' | translate }}
  </h5>
  <div id="dialog-content">
    <p>{{ 'global-message.content.1' | translate }}</p>

    <p>{{ 'global-message.content.2' | translate }}</p>

    <p>{{ 'global-message.content.3' | translate }}</p>

    <p>
      {{ 'global-message.content.4' | translate }}
    </p>

    <p [innerHTML]="'global-message.content.5' | translate"></p>
    <p [innerHTML]="'global-message.content.5-1' | translate"></p>
    <p [innerHTML]="'global-message.content.5-2' | translate"></p>
    <p [innerHTML]="'global-message.content.6' | translate"></p>
    <p>{{ 'global-message.content.7' | translate }}</p>
    <p>{{ 'global-message.content.8' | translate }}</p>
    <p>{{ 'global-message.content.9' | translate }}</p>
  </div>
  <button (click)="close()" class="btn">Continuer</button>
</div>

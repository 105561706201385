import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateSwService {
  constructor(public updates: SwUpdate, private logger: LoggerService) {
    if (updates.isEnabled) {
      interval(1000 * 60).subscribe(() =>
        updates.checkForUpdate().then(() => console.log('✅✅✅ checking for updates'))
      );
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    localStorage.clear();
    this.updates.activateUpdate().then(() => window.location.reload());
  }
}

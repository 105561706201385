<!-- {{ venteInfo.ventePub | json }} -->
<section class="section-infos-comple">
  <h6>Infos complémentaires: {{ 'section-vente-type.vente-type.' + (venteType$ | async) | translate }}</h6>
  <form [formGroup]="ventePubFG" *ngIf="(venteType$ | async) === 0">
    <ul class="sub-questions">
      
      <li [ngClass]="{'selected': venteInfo.ventePub.venteStatut}">
        <label>{{ 'section-vente-type.sub-questions.vente-publique.status' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="venteStatut">
            <mat-option *ngFor="let item of ventePubChoices" [value]="item">
              {{ 'section-vente-type.sub-questions.vente-publique.choice.' + item.id | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li [ngClass]="{'selected': venteInfo.ventePub.date_DateTime}">
        <label>{{ 'section-vente-type.sub-questions.vente-publique.date' | translate }}</label>
        <mat-form-field (click)="Open(1)" appearance="outline">
          <input
            type="text"
            matInput
            mwlFlatpickr
            [altInput]="true"
            [altFormat]="'j F Y - H:i'"
            [dateFormat]="'Z'"
            [allowInput]="false"
            [time24hr]="true"
            [enableTime]="true"
            [altInputClass]="'custom-datepicker'"
            formControlName="venteDate"
            #flatpickr1
          />
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.vente-publique.price' | translate }}</label>
        <mat-form-field appearance="outline">
          <input matInput (blur)="transformAmount($event)" formControlName="ventePrice" />
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.references-etude' | translate }}</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="references"
            placeholder="{{ 'section-vente-type.sub-questions.references-etude' | translate }}"
          />
        </mat-form-field>
      </li>
    </ul>
  </form>
  <form [formGroup]="venteGreGreFG" *ngIf="(venteType$ | async) === 1">
    <ul class="sub-questions">
      <!-- NABIL: Ajouter la classe "selected" sur le li quand on a fait un choix pour afficher l'icone -->
      <li>
        <label>{{ 'section-vente-type.sub-questions.gre-gre.offre' | translate }}</label>
        <mat-form-field appearance="outline">
          <input matInput (blur)="transformAmount($event)" formControlName="venteOffre" />
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.references-etude' | translate }}</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="references"
            placeholder="{{ 'section-vente-type.sub-questions.references-etude' | translate }}"
          />
        </mat-form-field>
      </li>
    </ul>
  </form>
  <form [formGroup]="venteBidditFG" *ngIf="(venteType$ | async) === 2">
    <ul class="sub-questions">
      <!-- NABIL: Ajouter la classe "selected" sur le li quand on a fait un choix pour afficher l'icone -->
      <li >
        <label>{{ 'section-vente-type.sub-questions.vente-publique.forced' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="venteForced" required>
            <mat-option >
            </mat-option>
            <mat-option [value]="1">
              {{ 'section-vente-type.sub-questions.vente-publique.yes' | translate }}
            </mat-option>
            <mat-option [value]="0">
              {{ 'section-vente-type.sub-questions.vente-publique.no' | translate }}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="venteBidditFG.get('venteForced')?.hasError('required')">
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.biddit.status' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="venteStatut">
            <mat-option *ngFor="let item of venteBidditChoices" [value]="item">
              {{ 'section-vente-type.sub-questions.biddit.choice.' + item.id | translate }}
            </mat-option>
          </mat-select>   
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.biddit.start-date' | translate }}</label>
        <mat-form-field (click)="Open(3)" appearance="outline">
          <input
            type="text"
            matInput
            mwlFlatpickr
            [altInput]="true"
            [altFormat]="'j F Y - H:i'"
            [dateFormat]="'Z'"
            [allowInput]="false"
            [time24hr]="true"
            [enableTime]="true"
            [altInputClass]="'custom-datepicker'"
            formControlName="venteStartDate"
            #flatpickr3
          />
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.biddit.end-date' | translate }}</label>
        <mat-form-field (click)="Open(4)" appearance="outline">
          <input
            type="text"
            matInput
            mwlFlatpickr
            [altInput]="true"
            [altFormat]="'j F Y - H:i'"
            [dateFormat]="'Z'"
            [allowInput]="false"
            [time24hr]="true"
            [enableTime]="true"
            [altInputClass]="'custom-datepicker'"
            formControlName="venteEndDate"
            #flatpickr4
          />
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.biddit.adju-date' | translate }}</label>
        <mat-form-field (click)="Open(5)" appearance="outline">
          <input
            type="text"
            matInput
            mwlFlatpickr
            [altInput]="true"
            [altFormat]="'j F Y - H:i'"
            [dateFormat]="'Z'"
            [allowInput]="false"
            [time24hr]="true"
            [enableTime]="true"
            [altInputClass]="'custom-datepicker'"
            formControlName="adjuDate"
            #flatpickr5
          />
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.biddit.info-date' | translate }}</label>
        <mat-form-field (click)="Open(6)" appearance="outline">
          <input
            type="text"
            matInput
            mwlFlatpickr
            [altInput]="true"
            [altFormat]="'j F Y - H:i'"
            [dateFormat]="'Z'"
            [allowInput]="false"
            [time24hr]="true"
            [enableTime]="true"
            [altInputClass]="'custom-datepicker'"
            formControlName="infoDate"
            #flatpickr6
          />
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.biddit.price' | translate }}</label>
        <mat-form-field appearance="outline">
          <input matInput (blur)="transformAmount($event)" formControlName="ventePrice" />
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.biddit.min-enchere' | translate }}</label>
        <mat-form-field appearance="outline">
          <input matInput (blur)="transformAmount($event)" formControlName="venteMinEnch" />
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li>
        <label>{{ 'section-vente-type.sub-questions.references-etude' | translate }}</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="references"
            placeholder="{{ 'section-vente-type.sub-questions.references-etude' | translate }}"
          />
        </mat-form-field>
      </li>
    </ul>
  </form>
</section>
<section class="section-cadastre">
  <h6>{{ 'section-cadastre.title' | translate }}</h6>
  <form [formGroup]="cadastreFG">
    <mat-form-field class="user-division" appearance="outline">
      <mat-label>{{ 'section-cadastre.division-label' | translate }}</mat-label>
      <input matInput placeholder="rue de campine" name="division" autocomplete="division" formControlName="division" />
      <mat-error *ngIf="venteFG.get('division')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="user-section" appearance="outline">
      <mat-label>{{ 'section-cadastre.section-label' | translate }}</mat-label>
      <input matInput placeholder="3" name="section" autocomplete="section" formControlName="section" />
      <mat-error *ngIf="venteFG.get('section')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="user-numCadastre" appearance="outline">
      <mat-label>{{ 'section-cadastre.cadastre-number-label' | translate }}</mat-label>
      <input matInput placeholder="11" name="numCadastre" autocomplete="numCadastre" formControlName="numCadastre" />
      <mat-error *ngIf="venteFG.get('numCadastre')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="user-rcni" appearance="outline">
      <mat-label>{{ 'section-cadastre.RCNI-label' | translate }}</mat-label>
      <input matInput placeholder="11" name="rcni" autocomplete="rcni" formControlName="rcni" />
      <mat-error *ngIf="venteFG.get('rcni')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</section>
<section class="section-vente-publique-lieu" *ngIf="(venteType$ | async) === 0">
  <h6>{{ 'section-lieu-vente.title' | translate }}</h6>
  <form [formGroup]="venteFG">
    <mat-form-field class="user-street" appearance="outline">
      <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
      <input matInput placeholder="rue de campine" name="street" autocomplete="street" formControlName="street" />
      <mat-error *ngIf="venteFG.get('street')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="user-num" appearance="outline">
      <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
      <input matInput placeholder="11" name="num" autocomplete="num" formControlName="num" />
      <mat-error *ngIf="venteFG.get('num')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="user-city" appearance="outline">
      <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
      <input matInput placeholder="11" name="city" autocomplete="city" formControlName="city" />
      <mat-error *ngIf="venteFG.get('city')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="user-zip" appearance="outline">
      <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
      <input matInput placeholder="11" name="zip" autocomplete="zip" formControlName="zip" />
      <mat-error *ngIf="venteFG.get('zip')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</section>
<section class="section-comment">
  <h6>{{ 'section-lieu-vente.description' | translate }}</h6>
  <mat-form-field appearance="outline">
    <textarea
      [(ngModel)]="comment"
      (change)="SetComment()"
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="5"
    ></textarea>
  </mat-form-field>
</section>

export const ventePubChoices = [
  {
    id: 0,
    name: 'Première séance'
  },
  {
    id: 1,
    name: 'Faculté de surenchère'
  },
  {
    id: 2,
    name: 'Deuxième séance / adjudication définitive'
  },
  {
    id: 3,
    name: 'Folle enchère'
  }
];

export const venteBidditChoices = [
  {
    id: 0,
    name: 'Vente online'
  },
  {
    id: 1,
    name: 'Folle enchère'
  }
];

<div id="step-money">
  <ng-container *ngIf="priceForProNoRed > 0; else noSplitInvoice">
    <div id="step-money-result" class="step-money-result-to-client">
      <div class="title">{{ 'section-money.total-invoiced-to-client' | translate }}</div>
      <div class="result">
        <span class="htva">
          <small>{{ 'section-money.htva' | translate }}</small>
          {{ (priceWithoutReduction - priceForProNoRed - reduction + immoReduction | htva)?.toFixed(2) }} €
        </span>
        <span>
          <small>{{ 'section-money.tvac' | translate }}</small>
          {{ (priceWithoutReduction - priceForProNoRed - reduction + immoReduction)?.toFixed(2) }} €
        </span>
      </div>
    </div>
    <div id="step-money-result">
      <div class="title">
        {{
          ($isAgency | async)
            ? ('section-money.total-invoiced-to-agency' | translate)
            : ('section-money.total-invoiced-to-notary' | translate)
        }}
      </div>
      <div class="result">
        <span class="htva">
          <small>{{ 'section-money.htva' | translate }}</small>
          {{ (priceForProNoRed - immoReduction | htva)?.toFixed(2) }} €
        </span>
        <span>
          <small>{{ 'section-money.tvac' | translate }}</small>
          {{ (priceForProNoRed - immoReduction)?.toFixed(2) }} €
        </span>
      </div>
    </div>
  </ng-container>
  <ng-template #noSplitInvoice>
    <div id="step-money-result">
      <div class="title">{{ 'section-money.total' | translate }}</div>
      <div class="result" *ngIf="!($urgence | async)">
        <span class="htva">
          <small>{{ 'section-money.htva' | translate }}</small>
          {{ (priceWithoutReduction - reduction | htva)?.toFixed(2) }} €
        </span>
        <span>
          <small>{{ 'section-money.tvac' | translate }}</small>
          {{ (priceWithoutReduction - reduction)?.toFixed(2) }} €
        </span>
      </div>
      <div class="result" *ngIf="$urgence | async">
        <span class="htva">
          <small>{{ 'section-money.htva' | translate }}</small>
          {{ (priceWithoutReduction | htva)?.toFixed(2) }} €
        </span>
        <span>
          <small>{{ 'section-money.tvac' | translate }}</small>
          {{ priceWithoutReduction?.toFixed(2) }} €
        </span>
      </div>
    </div>
  </ng-template>
</div>

import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comissions-info',
  templateUrl: './comissions-info.component.html',
  styleUrls: ['./comissions-info.component.scss']
})
export class ComissionsInfoComponent {
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @Input() comissions;
  
  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
    this.alert.nativeElement.classList.add('d-none');
  }

}

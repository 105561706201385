<div class="tooltip">
  <button class="tooltip-trigger"><fa-icon icon="question-circle"></fa-icon></button>
  <div [class]="'tooltip-container ' + tooltipPosition">
    <div *ngIf="title" class="tooltip-header">
      {{ title }}
    </div>
    <img *ngIf="mainVisual" class="tooltip-main-visual" [src]="'../../assets/images/' + mainVisual" alt="" />
    <div *ngIf="content" class="tooltip-content">
      <p class="caption" [innerHTML]="content"></p>
      <p *ngIf="emphasis" class="caption">
        <em>{{ emphasis }}</em>
      </p>
      <ul *ngIf="tooltipGallery && tooltipGallery.length > 0" class="tooltip-gallery">
        <li *ngFor="let item of tooltipGallery"><img [src]="'../../assets/images/' + item" alt="" /></li>
      </ul>
    </div>
  </div>
</div>

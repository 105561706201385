<header id="main-header">
  <h5>
    {{ isHainautOrNamurorNVN ? ('MDN-hainaut-namur-nvn.notary-services-page.title' | translate) : ('notary-services-page.title' | translate)}}
  </h5>
  <p>{{ 'notary-services-page.intro' | translate }}</p>
</header>
<div id="services-container" class="options-container">
  <div *ngFor="let serv of mdnServices" (click)="openDialog(serv.id)" class="option">
    <picture>
      <img [src]="serviceImgUrl(serv.fileName)" />
    </picture>
    <p>{{ 'section-services.mdn.' + serv.id | translate }}</p>
    <!-- <app-tooltip [content]="'section-services.peb-partiel.tooltip' | translate"></app-tooltip> -->
  </div>
</div>
<button [routerLink]="''" class="btn-primary" id="immo-services-trigger">
  {{ 'notary-services-page.immo-services-trigger' | translate }}
</button>

import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { PricingPlans } from '../../../core/enums/pricing-plan.enum';
import { Regions } from '../../../core/enums/region.enum';
import { estatesName } from '../../../core/data/estates';
@Component({
  selector: 'app-profile-pro',
  templateUrl: './profile-pro.component.html',
  styleUrls: ['./profile-pro.component.scss']
})
export class ProfileProComponent implements OnInit {
  @Input() profile;
  Regions = Regions;
  PricingPlans = PricingPlans;
  statusPlans;
  estatesType = estatesName;
  constructor() { }

  ngOnInit(): void {
    this.statusPlans = Object.values(this.PricingPlans);
  }

}

<!-- <mat-accordion [displayMode]="'flat'" class="login-accordeon" *ngIf="($isTablette | async)">
  <mat-expansion-panel class="panel-visit" [expanded]="false" hideToggle>
    <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
      <mat-panel-title>
        <h5>{{ 'login-modal.first-time.title' | translate }}</h5>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="content">
      <p class="body">
        {{ 'login-modal.first-time.content-1' | translate }}
      </p>
      <p class="body">
        {{ 'login-modal.first-time.content-2' | translate }}
      </p>
      <button class="btn-invert" (click)="firstTime()">
        {{ 'login-modal.first-time.action' | translate }}
      </button>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="panel-connect" [expanded]="true" hideToggle> -->

<ng-container *ngIf="!displayForgotInfoWindow; else forgotInfo">
  <mat-accordion [displayMode]="'flat'" class="login-accordeon">
    <mat-expansion-panel *ngIf="!($isLoggedIn | async)" class="panel-connect" [expanded]="!data.register">
      <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
        <mat-panel-title>
          <h5>
            {{ 'login-modal.connection.title' | translate }}
          </h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="content">
        <!-- <p class="body">
          {{ 'login-modal.connection.content' | translate }}
        </p> -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'login-modal.connection.email-or-id' | translate }}</mat-label>
          <input type="email" matInput placeholder="Email@example.com" cdkFocusInitial #email />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'login-modal.connection.password' | translate }}</mat-label>
          <input matInput type="password" placeholder="Your Password" #password />
        </mat-form-field>
        <!-- For security reasons we show the same message if the login or the password is incorrect -->
        <p *ngIf="$isLoggedError | async" class="password-reset-error caption">
          {{ 'login-modal.connection.password-error' | translate }}
        </p>
        <button class="btn-primary" (click)="signIn(email.value, password.value)">
          {{ 'login-modal.connection.action' | translate }}
        </button>
        <a (click)="showForgotInfo()" class="caption forgot">
          {{ 'login-modal.connection.forgot-info' | translate }}
        </a>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="panel-register" [expanded]="data.register">
      <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px">
        <mat-panel-title>
          <h5>{{ 'login-modal.register.title' | translate }}</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [id]="'user-form'" class="content">
        <p
          class="body"
          [innerHTML]="
            isHainautOrNamurorNVN
              ? ('MDN-hainaut-namur-nvn.login-modal.register.basic-content.intro' | translate)
              : ('login-modal.register.basic-content.intro' | translate)
          "
        ></p>
        <h6>{{ 'login-modal.register.basic-content.type-title' | translate }}</h6>
        <div class="options-container">
          <div [class]="'option ' + selectedClass(UserTypes.Agence)" (click)="selectUserType(UserTypes.Agence)">
            <picture>
              <img src="../../../assets/images/user-agence.svg" />
            </picture>
            <p>{{ 'common.user-types.' + UserTypes.Agence | translate }}</p>
          </div>
          <div [class]="'option ' + selectedClass(UserTypes.Notaire)" (click)="selectUserType(UserTypes.Notaire)">
            <picture>
              <img src="../../../assets/images/user-notaire.svg" />
            </picture>
            <p>{{ 'common.user-types.' + UserTypes.Notaire | translate }}</p>
          </div>
          <div
            [class]="'option ' + selectedClass(UserTypes.Installateur)"
            (click)="selectUserType(UserTypes.Installateur)"
          >
            <picture>
              <img src="../../../assets/images/user-installateur.svg" />
            </picture>
            <p>{{ 'common.user-types.' + UserTypes.Installateur | translate }}</p>
          </div>
          <div [class]="'option ' + selectedClass(UserTypes.Other)" (click)="selectUserType(UserTypes.Other)">
            <picture>
              <img src="../../../assets/images/user-type.svg" />
            </picture>
            <p>{{ 'common.user-types.' + UserTypes.Other | translate }}</p>
          </div>
        </div>
        <div
          id="status"
          *ngIf="
            userTypeSelected !== UserTypes.Agence &&
            userTypeSelected !== UserTypes.Notaire &&
            userTypeSelected !== UserTypes.Installateur
          "
        >
          <!-- <p class="body" id="user-other-explanation">
            {{ 'login-modal.register.user-other.content' | translate }}
          </p> -->
          <mat-form-field appearance="outline">
            <mat-label>{{ 'login-modal.register.basic-content.type-title' | translate }}</mat-label>
            <mat-select [(ngModel)]="userTypeSelected">
              <mat-option *ngFor="let other of others" (click)="setOther(other)" [value]="other">
                {{ 'common.user-types.' + other | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <button class="btn btn-accent" (click)="closeDialog()">
            {{ 'login-modal.register.user-other.action' | translate }}
          </button> -->
        </div>
        <form [formGroup]="userFG" (ngSubmit)="askForProAccount()">
          <div id="register-form">
            <mat-form-field appearance="outline" id="register-name">
              <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
              <input matInput placeholder="Doe" formControlName="lastName" required />
            </mat-form-field>
            <mat-form-field appearance="outline" id="register-firstname">
              <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
              <input matInput placeholder="Jane" formControlName="firstName" required />
            </mat-form-field>
            <mat-form-field appearance="outline" id="register-phone">
              <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
              <input matInput placeholder="0032123456789" formControlName="phone" required />
            </mat-form-field>
            <mat-form-field appearance="outline" id="register-email">
              <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
              <input matInput placeholder="janedoe@mail.com" type="email" formControlName="email" required />
            </mat-form-field>
            <mat-form-field appearance="outline" id="register-company">
              <mat-label>{{ 'section-user.disco.societe-name' | translate }}</mat-label>
              <input matInput placeholder="Jane Doe Corporation" formControlName="company" required />
            </mat-form-field>
            <mat-form-field appearance="outline" id="register-tva">
              <mat-label>{{ 'section-user.disco.societe-tva' | translate }}</mat-label>
              <input matInput placeholder="BE 1234567890" formControlName="tva" required />
            </mat-form-field>
          </div>
          <button class="btn" (click)="closeDialog()">
            {{ 'login-modal.register.user-form.cancel' | translate }}
          </button>
          <button type="submit" class="btn btn-accent">
            {{ 'login-modal.register.user-form.confirm' | translate }}
          </button>
        </form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
<ng-template #forgotInfo>
  <ng-container *ngIf="!emailSent; else emailSentWindow">
    <section class="forgot">
      <h5>
        {{ 'login-modal.forgot-info.title' | translate }}
      </h5>
      <div *ngIf="!displayEmailProposition" class="content">
        <p class="body">
          {{ 'login-modal.forgot-info.content' | translate }}
        </p>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'login-modal.connection.email-or-id' | translate }}</mat-label>
          <input type="email" matInput placeholder="Email@example.com" cdkFocusInitial #emailForgotten />
        </mat-form-field>
        <button class="btn-primary" (click)="checkEmailForgotten(emailForgotten.value)">
          {{ 'login-modal.forgot-info.check-email' | translate }}
        </button>
        <div class="not-found" *ngIf="emailNotInDB">
          <p>{{ 'login-modal.forgot-info.not-found-1' | translate }}</p>
          <p>{{ 'login-modal.forgot-info.not-found-2' | translate }}</p>
        </div>
      </div>
      <div *ngIf="displayEmailProposition" class="content">
        <p class="body">
          {{ 'login-modal.forgot-info.title1' | translate }} <b>{{ emailFoundForOrderUserId }}</b> ?
        </p>
        <button class="btn-primary" (click)="checkEmailForgotten(emailFoundForOrderUserId)">
          {{ 'common.confirm' | translate }}
        </button>
        <p [innerHTML]="'login-modal.email-sent-window.content1' | translate">
          {{ 'login-modal.email-sent-window.content1' | translate }}
        </p>
        <div class="not-found" *ngIf="emailNotInDB">
          <p>{{ 'login-modal.forgot-info.not-found-1' | translate }}</p>
          <p>{{ 'login-modal.forgot-info.not-found-2' | translate }}</p>
        </div>
      </div>
    </section>
  </ng-container>
</ng-template>
<ng-template #emailSentWindow>
  <section class="forgot">
    <h5>
      {{ 'login-modal.email-sent-window.title' | translate }}
    </h5>
    <div class="content">
      <p class="body">
        {{ 'login-modal.email-sent-window.content' | translate }}
      </p>
      <p class="body">
        {{ 'login-modal.email-sent-window.change-email' | translate }}
      </p>
      <ng-container *ngIf="displayEmailProposition">
        <p class="body">
          {{ 'login-modal.email-sent-window.title1' | translate }} <b>{{ emailFoundForOrderUserId }}</b>
        </p>
        <p [innerHTML]="'login-modal.email-sent-window.content1' | translate">
          {{ 'login-modal.email-sent-window.content1' | translate }}
        </p>
      </ng-container>
      <p class="body end">
        {{ 'login-modal.email-sent-window.thanks' | translate }}
      </p>
      <button class="btn-primary" (click)="close()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </section>
</ng-template>

<!-- <section class="panel-visit" *ngIf="!($isTablette | async)">
  <h5>{{ 'login-modal.first-time.title' | translate }}</h5>
  <div class="content">
    <p class="body">
      {{ 'login-modal.first-time.content-1' | translate }}
    </p>
    <p class="body">
      {{ 'login-modal.first-time.title' | translate }}
    </p>
    <button class="btn btn-invert" (click)="closeDialog()">
      {{ 'login-modal.first-time.action' | translate }}
    </button>
  </div>
</section>
<form>
  <section class="panel-connect" *ngIf="!($isTablette | async)">
    <h5>
      'login-modal.connection.title' | translate }}
    </h5>
    <div class="content">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'login-modal.connection.email' | translate }}</mat-label>
        <input matInput type="email" placeholder="Email@example.com" cdkFocusInitial #email />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'login-modal.connection.password' | translate }}</mat-label>
        <input matInput type="password" placeholder="Your Password" #password />
      </mat-form-field>
      <a
        (click)="forgotPassword(email.value)"
        class="caption"
        matTooltip="{{ 'login-modal.connection.password-reset-tooltip' | translate }}"
        matTooltipPosition="above"
        >{{ 'login-modal.connection.password-reset' | translate }}</a
      >
      <p *ngIf="(loginStatus | async) === 2" class="password-reset-error caption">
        {{ 'login-modal.connection.password-error' | translate }}
      </p>
      <p *ngIf="(loginStatus | async) === 3" class="password-reset-error caption">
        {{ 'login-modal.connection.email-error' | translate }}
      </p>
      <p
        *ngIf="!emailSent && ((emailAsked && email.value.length === 0) || emailNotInDB || emailError)"
        class="password-reset-error caption"
      >
        {{ 'login-modal.connection.password-reset-error' | translate }}
      </p>
      <p *ngIf="emailSent" class="password-reset-success caption">
        {{ 'login-modal.connection.password-reset-success' | translate }}
      </p>
      <button type="submit" class="btn-primary" (click)="signIn(email.value, password.value)">
        {{ 'login-modal.connection.action' | translate }}
      </button>
    </div>
  </section>
</form> -->
<!-- Connecté ? '{{ $isLoggedIn | async }}' -->

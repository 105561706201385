<div id="dialog-container" *ngIf="!sent; else congrats">
  <ng-container *ngIf="!displayAdd">
    <header id="dialog-header">
      <h5>
        <img src="../../../assets/images/service-poster.svg" />
        {{ 'poster-request-dialog.title' | translate }}
      </h5>
    </header>
    <div id="dialog-intro">
      <p>
        {{ 'poster-request-dialog.intro' | translate }}
      </p>
    </div>
    <div id="dialog-content">
      <label>Information sur le bien</label>
      <mat-form-field id="information-area" appearance="outline">
        <textarea
          [(ngModel)]="description"
          matInput
          placeholder="Ces informations seront reprises sur vos affiches"
        ></textarea>
      </mat-form-field>
      <button *ngIf="paperList.length === 0" (click)="displayAddPaper()" id="first-support" class="btn-accent">
        <fa-icon icon="plus"></fa-icon>
        {{
          isNamur
            ? ('MDN-namur.poster-request-dialog.add-paper' | translate)
            : ('poster-request-dialog.add-paper' | translate)
        }}
      </button>
      <ng-container *ngIf="paperList.length > 0">
        <ul id="paper-list">
          <li *ngFor="let paper of paperList; let i = index">
            <div class="paper-info">
              <span class="support">Papier | {{ paper.format }} | {{ paper.color }} | {{ paper.quantity }} ex</span>
              <strong class="price">= {{ paper.totalPrice | htva }}€</strong>
            </div>
            <button
              (click)="editPaper(i)"
              class="paper-edit"
              title="{{
                isNamur
                  ? ('MDN-namur.poster-request-dialog.edit-title' | translate)
                  : ('poster-request-dialog.edit-title' | translate)
              }}"
            >
              <fa-icon icon="pen"></fa-icon>
            </button>
            <button
              (click)="deletePaper(i)"
              class="paper-delete"
              title="{{
                isNamur
                  ? ('MDN-namur.poster-request-dialog.delete-title' | translate)
                  : ('poster-request-dialog.delete-title' | translate)
              }}"
            >
              <fa-icon icon="trash"></fa-icon>
            </button>
          </li>
          <li>
            <div class="paper-info">
              <span>{{ 'poster-request-dialog.forfait' | translate }}</span>
              <strong class="price">= {{ forfaitPrice | htva }}€</strong>
            </div>
          </li>
        </ul>
        <button (click)="displayAddPaper()" id="add-paper">
          <fa-icon icon="plus"></fa-icon>
          {{
            isNamur
              ? ('MDN-namur.poster-request-dialog.add-paper' | translate)
              : ('poster-request-dialog.add-paper' | translate)
          }}
        </button>
      </ng-container>
    </div>
    <footer id="dialog-footer">
      <button (click)="cancel()" class="btn">{{ 'common.cancel' | translate }}</button>
      <button (click)="confirmPapers()" class="btn btn-primary">{{ 'common.confirm' | translate }}</button>
    </footer>
  </ng-container>
  <ng-container *ngIf="displayAdd">
    <header id="dialog-header">
      <h5>
        <fa-icon icon="plus"></fa-icon>
        {{ 'poster-request-dialog.creation.title' | translate }}
      </h5>
    </header>
    <form id="dialog-content">
      <div class="form-element" id="format-choice">
        <label>{{ 'poster-request-dialog.creation.format' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select name="selected-format" [(ngModel)]="selectedPaper.selectedFormat">
            <mat-option *ngFor="let option of options; let i = index" (click)="setFormat(i)" [value]="i">
              {{ option.format }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-element" id="couleur-choice">
        <label>{{ 'poster-request-dialog.creation.color' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select name="selected-color" [(ngModel)]="selectedPaper.selectedColor">
            <mat-option *ngFor="let color of options[selectedPaper.selectedFormat].colors; let i = index" [value]="i">
              {{ color }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-element" id="number-choice">
        <label for="poster-number">{{ 'poster-request-dialog.creation.number' | translate }}</label>
        <mat-form-field name="nombre" appearance="outline">
          <input
            id="poster-number"
            type="number"
            name="selected-quantity"
            min="50"
            [(ngModel)]="selectedPaper.quantity"
            matInput
            placeholder="{{ 'poster-request-dialog.creation.number-placeholder' | translate }}"
          />
        </mat-form-field>
      </div>
    </form>
    <footer id="dialog-footer">
      <button (click)="backToList()" class="btn">{{ 'common.cancel' | translate }}</button>
      <button *ngIf="!editing" (click)="addPaper()" class="btn-primary">{{ 'common.add' | translate }}</button>
      <button *ngIf="editing" (click)="saveEditPaper()" class="btn-primary">
        {{ 'common.edit' | translate }}
      </button>
    </footer>
  </ng-container>
</div>

<ng-template #congrats>
  <div id="dialog-confirm">
    <picture>
      <fa-icon icon="check"></fa-icon>
    </picture>
    <h6>{{ 'specific-request-dialog.success-title' | translate }}</h6>
    <p>
      {{ 'specific-request-dialog.success-content' | translate }}
    </p>
    <button (click)="cancel()" class="btn">{{ 'specific-request-dialog.success-action' | translate }}</button>
  </div>
</ng-template>

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { SendEmailService } from 'src/app/core/services/send-email.service';
import { getUserInfo } from 'src/app/core/store/order/order.selectors';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-paper-request-dialog',
  templateUrl: './paper-request-dialog.component.html',
  styleUrls: ['./paper-request-dialog.component.scss']
})
export class PaperRequestDialogComponent implements OnInit {
  tomorrow = new Date();

  choice = {
    A3: 1,
    A4: 1,
    officeId: 0,
    date: new Date()
  };

  A3 = {
    price: 50,
    size: 500
  };

  A4 = {
    price: 50,
    size: 1000
  };

  officeChoices = [
    { id: 0, name: 'MDN Mons - Rue de la halle 38' },
    { id: 1, name: 'MDN Charleroi - Quai Arthur Rimbaud 14' },
    { id: 2, name: 'MDN Tournai - Place de Lille 3' }
  ];

  connectedUser: User;
  sent = false;

  get totalPrice() {
    return this.choice.A3 * this.A3.price + this.choice.A4 * this.A4.price;
  }

  get dateSelected() {
    return this.choice.date.toLocaleDateString('fr-BE');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog },
    private store: Store<AppState>,
    private sendEmailService: SendEmailService
  ) {}

  ngOnInit() {
    this.store.select(getUserInfo).subscribe(u => (this.connectedUser = u));
  }

  close() {
    this.data.dialog.closeAll();
  }

  sendEmail() {
    const formattedMsg = `
    Quantité Rame A3: ${this.choice.A3}
    <br/>
    Quantité Rame A3: ${this.choice.A4}
    <br/>
    Je désire retirer ma commande au bureau suivant: ${this.officeChoices[this.choice.officeId].name}
    <br/>
    En date du: ${this.dateSelected}
    <br/>
    Prix total: ${this.totalPrice}€
    `;

    const result = Object.assign(
      {
        subject: 'Commande papier: ',
        message: formattedMsg
      },
      {
        email: this.connectedUser.email,
        firstName: this.connectedUser.firstname,
        lastName: this.connectedUser.lastname,
        phone: this.connectedUser.phone,
        address: this.connectedUser.address
      }
    );

    this.sendEmailService.sendNotaryRequest(result).subscribe(
      val => {
        this.sent = true;
      },
      response => {
        this.sent = false;
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { IOrder } from 'src/app/core/store/order/order.reducer';
import { AppState } from 'src/app/interfaces';
import { Store } from '@ngrx/store';
import * as orderSelector from '../../core/store/order/order.selectors';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-step-money-result',
  templateUrl: './step-money-result.component.html',
  styleUrls: ['./step-money-result.component.scss']
})
export class StepMoneyResultComponent implements OnInit {
  order: IOrder;
  priceWithoutReduction: number;
  reduction: number;
  $urgence: Observable<boolean>;
  $isAgency: Observable<boolean>;

  constructor(private store: Store<AppState>, private authService: AuthService) {}

  ngOnInit() {
    this.store.select(orderSelector.getOrder).subscribe(o => (this.order = o));
    this.store.select(orderSelector.getReduction).subscribe(reduc => (this.reduction = reduc));
    this.$urgence = this.store.select(orderSelector.getUrgence);
    this.$isAgency = this.authService.isAgency;
    this.store.select(orderSelector.getPriceWithoutReduction).subscribe(price => {
      this.priceWithoutReduction = price;
    });
  }

  get immoReduction() {
    return this.order.reductions
      .filter(
        r =>
          r.id < 1000 &&
          r.id !== 1 &&
          r.id !== 11 &&
          r.id !== 4 &&
          r.id !== 8 &&
          r.id !== 6 &&
          r.id !== 9 &&
          r.id !== 15 &&
          r.id !== 16 &&
          r.id !== 51 &&
          r.id !== 55 &&
          r.id !== 56 &&
          r.id !== 57 &&
          r.id !== 17
      )
      .reduce((a, b) => a + +b.price, 0);
  }

  get priceForProNoRed() {
    return this.order.productsForAgency.reduce((a, b) => a + +b.price, 0);
  }
}

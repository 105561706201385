import { Component, OnInit, Inject } from '@angular/core';
import { ventes } from 'src/app/core/data/ventes';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SendEmailService } from 'src/app/core/services/send-email.service';
import { User } from 'src/app/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { getUserInfo } from 'src/app/core/store/order/order.selectors';

@Component({
  selector: 'app-signs-request-dialog',
  templateUrl: './signs-request-dialog.component.html',
  styleUrls: ['./signs-request-dialog.component.scss']
})
export class SignsRequestDialogComponent implements OnInit {
  ventes = [...ventes];

  priceVP = 160;
  priceGG = 8;
  prixBiddit1 = 30;
  prixBiddit2 = 25;

  txtVentePub = `Pack comprenant 5 affiches A4, 5 Affiches A3 et 1 affiche A3 autocollante`;
  txtVenteBiddit = `Panneaux BIDDIT : ${this.prixBiddit1} € HTVA/Panneau et ${this.prixBiddit2} € HTVA/Panneau (min 10 exemplaires)`;
  txtVenteGG = `Panneaux triangulaires bleu et blanc (70cm x 100 cm) : 7.50 €/Panneau (commande de min 50 panneaux par étude)`;

  selectedPanel = {
    venteType: 0,
    quantity: 10
  };

  connectedUser: User;
  sent = false;

  get computedPrice() {
    return this.selectedPanel.venteType === 0
      ? this.priceVP
      : this.selectedPanel.venteType === 2
      ? this.selectedPanel.quantity >= 10
        ? this.prixBiddit2
        : this.prixBiddit1
      : this.priceGG;
  }

  get totalPrice() {
    return this.selectedPanel.venteType === 0 ? this.computedPrice : this.computedPrice * this.selectedPanel.quantity;
  }

  get venteType(): string {
    return this.selectedPanel.venteType === 0
      ? 'Vente publique'
      : this.selectedPanel.venteType === 1
      ? 'Vente gré à gré'
      : 'Vente Biddit';
  }

  get minQuantity() {
    return this.selectedPanel.venteType === 1 ? 50 : 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog },
    private store: Store<AppState>,
    private sendEmailService: SendEmailService
  ) {}

  ngOnInit() {
    this.store.select(getUserInfo).subscribe(u => (this.connectedUser = u));
  }

  close() {
    this.data.dialog.closeAll();
  }

  SetDefaultQuantity() {
    this.selectedPanel.quantity = this.selectedPanel.venteType === 2 ? 10 : 50;
  }

  sendEmail() {
    const formattedMsg =
      this.selectedPanel.venteType === 0
        ? `
    Type de vente: ${this.venteType}
    <br/>
    ${this.txtVentePub}
    <br/>
    Prix total: ${this.totalPrice}€ HTVA
    `
        : `
    Type de vente: ${this.venteType}
    <br/>
    ${this.selectedPanel.venteType === 1 ? this.txtVenteGG : this.txtVenteBiddit}
    <br/>
    Nombre d'exemplaires: ${this.selectedPanel.quantity}
    <br/>
    Tarif unitaire: ${this.computedPrice}€ HTVA
    <br/>
    Prix total: ${this.totalPrice}€ HTVA
    `;

    const result = Object.assign(
      {
        subject: 'Commande Panneaux: ',
        message: formattedMsg
      },
      {
        email: this.connectedUser.email,
        firstName: this.connectedUser.firstname,
        lastName: this.connectedUser.lastname,
        phone: this.connectedUser.phone,
        address: this.connectedUser.address
      }
    );

    this.sendEmailService.sendNotaryRequest(result).subscribe(
      val => {
        this.sent = true;
      },
      response => {
        this.sent = false;
      }
    );
  }
}

export const PEB = 1;
export const PEB_Partiel = 11;
export const AUDIT = 3;
export const MINI_AUDIT = 108;
export const ASBESTOS = 118;
export const ELEC = 4;
export const ELEC_SUPP = 8;
export const CITERNE_ENTERREE = 9;
export const EXTRA = 10;
export const CITERNE_AERIENNE = 6;
export const DEPLACEMENT = 13;
export const PLAN = 14;
export const GAZ_CONTROLE = 15;
export const GAZ_SUPP = 16;
export const GAZ_CONTROLE_PERIO = 100;
export const BLOWER_DOOR = 141;

export const ISO = 17;

export const DESCRIPTIF = 27;
export const PLAN3D = 26;
export const VISITE360 = 21;
export const VISITMATTERPORT = 113;
export const PHOTO = 18;
export const PHOTODRONE = 19;
export const VIDEO_DRONE = 101;
export const VIDEO = 20;
export const VIDEO_TEASING = 111;
export const QR = 112;
export const RESP_PEB = 22;
export const ELEC_NONDOM_MAX125A = 23;
export const ELEC_NONDOM_OVER125A = 24;
export const PEBCHAUFFAGE = 28;

export const PANELBIDDIT = 29;
export const PANELBIDDIT_SUPPORT = 30;
export const PANEL = 32;
export const PANEL_SUPPORT = 33;
export const SMS = 34;
export const ELEC_PHOTOVOLTA = 37;

export const PANEL_STANDARD = 102;
export const PANEL_XL_CERTI = 103;
export const PANEL_DEVIS = 104;

export const IMMOCOMBI = 50;
export const VISIOCOMBI = 51;
export const CONTROLECOMBI = 52;

export const PEB_SUPP = 11111;
// export const AUDIT_SUPP = 11112;

export const SPECIFIC = -1;
export const PLAQUETTE = -2;
export const VISIT = -5;
export const DESCRIPTIF_URBA = -6;
export const LIVRET = -7;
export const AFFICHETTE = -8;
export const AMIANTE = -11;
export const NETTMAISON = -13;
export const NETTJARDIN = -14;
export const VISITADVALORIS = 10023;
export const LOCKSMITH = 10024;
export const FRAIS_DOSSIER = -10;
export const URGENT_PRO = -12;
export const PRINT = -40;
export const PRINT_A_LA_CARTE = -41;
export const PRINT_A_LA_CARTE_BIS = -411;
export const PANEL_XL = -42;

export const PublicationNotaireBE = 10000;
export const PublicationImmowebXL = 10001;
export const ImmovlanOnline = 10002;
export const NotaireBEOnline = 10003;
export const BidditOnline = 10004;
export const Fees = 10005;
export const BidditFees = 10006;
export const FullPress = 10007;
export const PublicationVlan = 10008;
export const PublicationLeSoir = 10009;
export const PublicationNiewsblad = 10010;
export const PublicationHetLaatsteNieuws = 10011;
export const publicationLaLibre = 10012;
export const PublicationDezeWeekRingBXL = 10013;
export const PublicationBruzz = 10014;
export const PublicationDeZondag = 10015;
export const PublicationRondomVlaamsBrabant = 10016;
export const PublicationRomdomPajottenland = 10017;
export const PublicationRondomNoordrant = 10018;
export const PublicationProximag = 10019;
export const PublicationEcho = 10020;
export const PublicationDeTijd = 10021;
export const PublicationDeMorgen = 10022;

export const PublicationAvenir = 10025;
export const PublicationProximagAndenne = 10026;
export const PublicationProximagCiney_Condroz = 10027;
export const PublicationProximagDinant_Bauraing = 10028;
export const PublicationProximagGembloux = 10029;
export const PublicationProximagNamur = 10030;
export const PublicationProximagPhilippeville = 10031;
export const PublicationProximagCouvinChimay = 10032;
export const PublicationProximagCiney_Dinant_Beauraing = 10033;
export const PublicationProximagCharleroi = 10034;
export const PublicationProximagBrabantWallonEst = 10035;
export const PublicationProximagHuy = 10036;
export const PublicationProximagHesbaye = 10037;
export const PublicationProximagFamenne = 10038;
export const PublicationProximagArdennes = 10039;

export const VisitGil4S = 10040;
export const VisitGil6S = 10041;
export const VisitGilBoundless_3M = 10042;
export const VisitGil2Times_Bid = 10043;
export const VisitGilBoundless_Bid = 10044;
export const VlanEditionLocal = 10045;

export const ProximagNamurBW = 10050;
export const ProximagLuxembourg = 10051;
export const ProximagLiege = 10052;
export const ProximagHainautOcc = 10053;
export const ProximagHainautOutOfOcc = 10054;
export const PublicationNotaireBeZimmo = 10055;
export const PublicationFbIns = 10056;
export const ZYVA = 99999;

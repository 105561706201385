import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { getIsLoggedIn } from 'src/app/core/store/order/order.selectors';

@Component({
  selector: 'app-services-choice-dialog',
  templateUrl: './services-choice-dialog.component.html',
  styleUrls: ['./services-choice-dialog.component.scss']
})
export class ServicesChoiceDialogComponent implements OnInit {
  login = new EventEmitter();
  toNotaryServices = new EventEmitter();
  $isLoggedIn: Observable<boolean>;
  isLoggedIn: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { dialog: MatDialog }, private store: Store<AppState>) {}

  ngOnInit() {
    this.$isLoggedIn = this.store.select(getIsLoggedIn);
    this.$isLoggedIn.subscribe(i => (this.isLoggedIn = i));
  }

  close() {
    this.data.dialog.closeAll();
  }

  continue() {
    if (this.isLoggedIn) {
      this.toNotaryServices.emit();
    } else {
      this.login.emit();
    }
  }
}

import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { certiCoreBaseUrl } from 'src/environments/environment';
import { AppState } from 'src/app/interfaces';
import { Store } from '@ngrx/store';
import { getToken } from '../store/order/order.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const urlPath = req.url.toLowerCase();

    /** Does not require token */
    if (
      !urlPath.startsWith(certiCoreBaseUrl) ||
      (urlPath.startsWith(certiCoreBaseUrl) && urlPath.includes('auth/login'))
    ) {
      return next.handle(req);
    }

    return this.store.select(getToken).pipe(
      filter(token => !!token),
      switchMap(token => {
        const clonedRequest = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        return next.handle(clonedRequest);
      })
    ) ?? next.handle(req);
  }
}

/**
 * Joins multiple paths together, removing leading and trailing slashes
 * while keeping the first part (protocol) intact.
 */
export function joinPaths(...paths: string[]): string {
  return paths
    .map((part, index) => {
      // Keep the first part (protocol) intact
      if (index === 0) return part.replace(/\/+$/, '');

      // For subsequent parts, remove leading and trailing slashes
      return part.replace(/^\/+|\/+$/g, '');
    })
    .join('/');
}

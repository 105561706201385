/**
 * ENUM for PricingPlans
 **/
export enum PricingPlans {
  NONE = 0,
  BRONZE = 1,
  SILVER = 2,
  GOLD = 3,
  PLATINUM = 4,
  BRONZE_FLANDRE = 11,
  SILVER_FLANDRE = 12,
  GOLD_FLANDRE = 13,
  PLATINUM_FLANDRE = 14
}
